import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import { useParams } from 'react-router-dom';
import translation, { getIgo } from "../../HTTP/translation";
import "./GoogleCSE.css"
import "./articles.css"
import GoogleCSE from "./GoogleCSE";
import { CountryBar } from "./countryBar";
import Map from "../Map/Map";
import { use } from "react";


export default function WhatThisCountryTalkAbout () {
    const [displayMap, setDisplayMap] = useState(false)
    const [selectedIgo, setSelectedIgo] = useState({name:"Selectionner une OIG", country_list: ""})
    const [igo, setIgo] = useState([])
    const [igoReady, setIgoReady] = useState(false)

    useEffect(() => {
        const funIgo = async () => {
            const res = await getIgo()
            setIgo(res)
            const url = new URL(window.location.href)
            if (url.searchParams.get('igo') !== null) {
                const igoSelected = res.find((i) => i.name === url.searchParams.get('igo'))
                if (igoSelected) {
                    setSelectedIgo(igoSelected)
                } else {
                    url.searchParams.delete('igo')
                    setSelectedIgo(res[0])
                }
            } else if (sessionStorage.getItem('search_igo') !== null) {
                const igoFound = res.find((i) => i.name === sessionStorage.getItem('search_igo'))
                if (igoFound) {
                    setSelectedIgo(igoFound)
                } else {
                    sessionStorage.removeItem('search_igo')
                    setSelectedIgo(res[0])
                }
            } else {
                setSelectedIgo(res[0])
            }
        }
        funIgo()
        setIgoReady(true)
    }, [])

    if (sessionStorage.getItem('search_country_country') !== null) {
        sessionStorage.removeItem('search_country_country')
    }
    if (sessionStorage.getItem('search_themes_country') !== null) {
        sessionStorage.removeItem('search_themes_country')
    }
    sessionStorage.setItem('listmiddle', "De quoi parle-t-on ?")

    let { countrycode } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 

    const queryCountry = new URLSearchParams(window.location.search).get('country')
    let country = countrycode ? allCountries.find((c) => c.code === countrycode) : null
    country = country
            || JSON.parse(sessionStorage.getItem("search_country"))
            || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
            || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" }
    if (JSON.parse(sessionStorage.getItem('search_country')) === null) {
        sessionStorage.setItem('search_country',JSON.stringify(country))
    }
    
    const [selectedCountry] = useState(country)

    const selectNewCountry = async (country) => {
        const langDest = await translation.getLangFromCountry(country.code)
        let trad
        if (langDest !== document.documentElement.lang) trad = await translation.getTranslation(document.documentElement.lang, langDest, country.name)
        sessionStorage.setItem("search_query", (langDest !== document.documentElement.lang) ? trad : country.name)
        window.location.href = `/${country.code}/what-this-country-talk-about`
        sessionStorage.setItem("search_country", JSON.stringify(country))
    }

    const handleDisplayMap = () => {
		if(displayMap) {
			setDisplayMap(false)
		} else {
			setDisplayMap(true)
		}
	}
    
    return (
        <>
        <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
                igo = {igo}
                igoReady = {igoReady}
                selectedIgo = {selectedIgo}
                setSelectedIgo = {(igo) => setSelectedIgo(igo)}
                displayMap = {() => handleDisplayMap()}
            />
        <div className="flex">
            <div className="flex-1">
                {
                    selectedIgo.name !== "Séléctionner une OIG" && (
                        <>
                            <div className="flex justify-between items-center ml-2 mr-2">
                                <h1>{selectedIgo.name}</h1>
                                <img className="w-20 h-20" src={process.env.PUBLIC_URL + '/igo_flags/' + selectedIgo.name + ".svg"} alt={"Logo " + selectedIgo.name} />
                            </div>
                        </>
                    )
                }
                <Map 
                    selectedCountry={selectedCountry} 
                    setSelectedCountry = {(country) => selectNewCountry(country)}
                    displayMap = {displayMap}
                    setDisplayMap = {(hide) => setDisplayMap(hide)}
                />
                <GoogleCSE 
                    selectedCountry = {country}
                />
            </div>
            <div id="pays_large" className="w-60 bg-neutral-50">
            <CountryBar
                selectedCountry = {country}
                setSelectedCountry = {(country) => selectNewCountry(country)}
                selectedIgo = {selectedIgo}
                setSelectedIgo = {(igo) => setSelectedIgo(igo)}
                displayMap = {() => handleDisplayMap()}
                isMobile = {false}
                igo = {igo}
                igoReady = {igoReady}
            />
            </div>
        </div>
        </>
    )
}