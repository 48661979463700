import React, { useEffect, useState } from 'react';
import { getArticlesByAuthorId, removeOwnArticle, createReview } from '../../HTTP/articles';
import { encodeHTML, formatDate, formatDateExtended, htmlDecode, removeHtmlTags } from '../../Helper/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '../../Helper/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PeopleIcon from '@mui/icons-material/People';
import { Add } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { countries } from '../../global/countriesAndSources';
import CloseIcon from '@mui/icons-material/Close';
import userApi from "../../HTTP/user";

export default function MyArticles() {
    if (localStorage.getItem("subscriptions") === null) {
        window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }
    const url = new URL(window.location.href)
      if (url.searchParams.get('page') === null) {
          url.searchParams.set('page', 1);
          window.history.pushState({}, '', url);
      }
      if (url.searchParams.get('sortby') === null) {
        url.searchParams.set('sortby', 'title');
        window.history.pushState({}, '', url);
    } if (url.searchParams.get('filter') === null) {
      url.searchParams.set('filter', -1);
      window.history.pushState({}, '', url);
  }

  const [articles, setArticles] = useState({count:0, rows:[]});
  const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
  const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
  const [sortBy, setSortBy] = useState(url.searchParams.get('sortby') === null ? 'title' : url.searchParams.get('sortby'))
  const [itemsPerPage] = useState(5);
  const [filter, setFilter] = useState(url.searchParams.get('filter') === null ? -1 : url.searchParams.get('filter'))
  const user = JSON.parse(localStorage.getItem("user"))
  
  // États pour les articles sélectionnés
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [isCurrentPageAllSelected, setIsCurrentPageAllSelected] = useState(false);
  const [hasDraftArticles, setHasDraftArticles] = useState(false);
  
  // État pour le popup de création de revue de presse
  const [showPopup, setShowPopup] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const funArticles = async () => {
      const resArticles = await getArticlesByAuthorId(user.id, (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage, searchTerm, sortBy, filter)
      setArticles(resArticles)
      
      // Vérifier si tous les articles de la page actuelle sont déjà sélectionnés
      updateCurrentPageSelectionStatus(resArticles.rows, selectedArticles);
    }
    funArticles()
  }, [currentPage, searchTerm, sortBy, filter])
  
  // Charger les revues existantes
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await userApi.getUserReviews();
        setReviews(reviewsData.reviews);
      } catch (error) {
        console.log('Erreur lors du chargement des revues de presse', error);
      }
    };
    
    fetchReviews();
  }, []);

  // Vérifier si la liste d'articles sélectionnés contient des brouillons
  useEffect(() => {
    // Un article est en brouillon si visible === 0
    const containsDrafts = selectedArticles.some(article => article.visible === 0);
    setHasDraftArticles(containsDrafts);
  }, [selectedArticles]);

  // Mettre à jour le statut de sélection de la page actuelle
  const updateCurrentPageSelectionStatus = (currentPageArticles, selectedArticlesList) => {
    if (currentPageArticles.length === 0) {
      setIsCurrentPageAllSelected(false);
      return;
    }
    
    const allCurrentPageSelected = currentPageArticles.every(article => 
      selectedArticlesList.some(selected => selected.id === article.id)
    );
    
    setIsCurrentPageAllSelected(allCurrentPageSelected);
  };
  
  const handleSelectedValueOnList = (e) => {
    window.location.href = `/myarticles?page=1&filter=${e.target.value}${searchTerm === '' ? '' : '&query='+searchTerm}`
  }

  const handleSearch = (e) => {
    e.preventDefault();
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/myarticles?page=1&query=" + searchTerm
  };

  const handleAdd = () => {
    window.location.href = "/myarticles/create"
  };

  const handleEdit = (id) => {
    window.location.href = "/myarticles/edit/" + id
  };

  const handleDelete = (item) => {
        removeOwnArticle(item).then(async () => {
          alert("Removed successfully !")
        }).catch((err) => alert("An error occured while removing the article"))
        window.location.href = "/myarticles"
  };

  const handleViewArticle = (id) => {
    window.location.href = `https://static.zemus.info/article/?id=${id}`
  }

  // Gérer la sélection/désélection d'un article
  const handleSelectArticle = (article) => {
    setSelectedArticles(prevSelected => {
      // Vérifier si l'article est déjà sélectionné
      const isSelected = prevSelected.some(item => item.id === article.id);
      
      let newSelectedList;
      if (isSelected) {
        // Si déjà sélectionné, on le retire de la liste
        newSelectedList = prevSelected.filter(item => item.id !== article.id);
      } else {
        // Sinon, on l'ajoute à la liste
        newSelectedList = [...prevSelected, article];
      }
      
      // Mettre à jour le statut de sélection pour la page actuelle
      updateCurrentPageSelectionStatus(articles.rows, newSelectedList);
      
      return newSelectedList;
    });
  };

  // Gérer la sélection/désélection de tous les articles de la page actuelle
  const handleSelectAllCurrentPage = () => {
    setSelectedArticles(prevSelected => {
      if (isCurrentPageAllSelected) {
        // Si tous les articles de la page actuelle sont déjà sélectionnés, on les désélectionne
        const newSelectedList = prevSelected.filter(selected => 
          !articles.rows.some(article => article.id === selected.id)
        );
        setIsCurrentPageAllSelected(false);
        return newSelectedList;
      } else {
        // Sinon, on ajoute tous les articles de la page actuelle qui ne sont pas déjà sélectionnés
        const currentPageIds = articles.rows.map(article => article.id);
        const alreadySelectedIds = prevSelected.map(article => article.id);
        
        // Filtrer pour ne garder que les articles qui ne sont pas dans la page actuelle
        const selectedFromOtherPages = prevSelected.filter(
          selected => !currentPageIds.includes(selected.id)
        );
        
        // Ajouter tous les articles de la page actuelle
        const newSelectedList = [...selectedFromOtherPages, ...articles.rows];
        setIsCurrentPageAllSelected(true);
        return newSelectedList;
      }
    });
  };

  // Fonctions pour les actions en masse
  const handleCreatePressReview = () => {
    // Si des brouillons sont présents, cette fonction ne devrait pas être appelée
    if (hasDraftArticles) return;
    
    // Ouvrir le popup pour créer une revue de presse
    setShowPopup(true);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleAddPressReview = () => {
    const theme = document.getElementById('review_theme').value;
    const presentation = document.getElementById('review_presentation').value;
    const publishable_review = document.getElementById('publishable_review').checked;
    
    // Préparer les données d'articles pour la création de la revue
    const articlesForReview = selectedArticles.map(article => ({
      country: countries.find((c) => c.code === user.country).name,
      description: encodeHTML(article.description).slice(0, 150),
      image: '',
      link: `https://static.zemus.info/article/?id=${article.id}`,
      title: article.title
    }));
    
    createReview(theme, presentation, articlesForReview, publishable_review)
      .then((res) => {
        console.log(res);
        setShowPopup(false);
        alert('Revue de presse créée avec succès');
        window.location.href = `https://static.zemus.info/?id=${res.newReview}&redirect=myarticles`;
      })
      .catch(error => {
        console.error("Erreur lors de la création de la revue de presse", error);
        alert("Une erreur est survenue lors de la création de la revue de presse.");
      });
  };

  const handleAddToPressReview = () => {
    // Si des brouillons sont présents, cette fonction ne devrait pas être appelée
    if (hasDraftArticles) return;

    // Préparer les données pour les articles sélectionnés
    const data = selectedArticles.map(article => ({
      country: countries.find((c) => c.code === user.country).name,
      description: encodeHTML(article.description).slice(0, 150),
      image: '',
      link: `https://static.zemus.info/article/?id=${article.id}`,
      title: article.title
    }));

    // Rediriger vers la page d'ajout à une revue de presse
    window.location.href = `/addtoreview?data=${encodeURIComponent(JSON.stringify(data))}`;
  };

  const handleAddToFriendPressReview = () => {
    // Si des brouillons sont présents, cette fonction ne devrait pas être appelée
    if (hasDraftArticles) return;

    // Préparer les données pour les articles sélectionnés
    const data = selectedArticles.map(article => ({
      country: countries.find((c) => c.code === user.country).name,
      description: encodeHTML(article.description).slice(0, 150),
      image: '',
      link: `https://static.zemus.info/article/?id=${article.id}`,
      title: article.title
    }));

    // Rediriger vers la page d'ajout à une revue de presse d'un ami
    window.location.href = `/addtofriendreview?data=${encodeURIComponent(JSON.stringify(data))}`;
  };

  const handleBulkDelete = () => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${selectedArticles.length} article(s) ?`)) {
      // Ici on pourrait implémenter une suppression en masse via une API
      // Pour l'instant, on supprime séquentiellement chaque article
      let deletePromises = selectedArticles.map(article => removeOwnArticle(article));
      
      Promise.all(deletePromises)
        .then(() => {
          alert("Articles supprimés avec succès !");
          window.location.href = "/myarticles";
        })
        .catch(err => {
          alert("Une erreur est survenue lors de la suppression de certains articles.");
        });
    }
  };

  // Changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    url.searchParams.set('page', pageNumber);
    window.history.pushState({}, '', url);
  };

  const formatVisible = (visible) => {
    switch (visible) {
      case 0: return "Brouillon"
      case 1: return "En ligne"
      case 2: return "En ligne (en cours de vérification)"
      case 3: return "En ligne (vérifié)"
      default: return "-"
    }
  }

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      {/* Popup de création de revue de presse */}
      {showPopup && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4">
              <div className="flex items-center justify-between p-4 border-b">
                <h2 className="text-xl font-bold text-gray-800">Créer une revue de Presse</h2>
                <button onClick={togglePopup} className="text-gray-500 hover:text-gray-700">
                  <CloseIcon />
                </button>
              </div>
              <div className="p-4">
                <div className="mb-4">
                  <input
                    name="theme"
                    placeholder="Thème..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="text"
                    maxLength="255"
                    id="review_theme"
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    name="presentation"
                    placeholder="Description..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows={4}
                    maxLength="255"
                    id="review_presentation"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <input
                    name="publishable_review"
                    type="checkbox"
                    id="publishable_review"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="publishable_review" className="ml-2 text-gray-700">
                    Autoriser mes amis à publier leurs propres favoris dans cette revue de presse
                  </label>
                </div>
                <div className="mb-4">
                  <button
                    className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
                    onClick={handleAddPressReview}
                  >
                    Valider
                  </button>
                </div>
                {selectedArticles.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-4">
                    {selectedArticles.map((article, i) => (
                      <div key={i} className="bg-gray-100 rounded-md p-2 text-sm">
                        {article.title.substring(0, 30)}{article.title.length > 30 ? '...' : ''}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex items-center justify-between mt-10 mb:mt-0 mb-4">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Mes articles
        </h1>
      </div>
      <form onSubmit={handleSearch} className="w-full mb-4">
        <div className="flex flex-col space-y-3 lg:flex-row lg:space-y-0 lg:space-x-3">
          {/* Container principal qui prend toute la largeur disponible */}
          <div className="flex-1 flex space-x-2">
            <input
              type="text"
              placeholder="Rechercher..."
              className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 shadow-sm transition-colors focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              type="submit"
              className="px-4 py-2.5 bg-blue-500 hover:bg-blue-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <SearchIcon />
            </button>
            <button
              type="button"
              className="px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              onClick={handleAdd}
            >
              <AddIcon />
            </button>
          </div>

          {/* Select avec une largeur fixe */}
          <select 
            value={filter} 
            onChange={handleSelectedValueOnList}
            className="w-full lg:w-48 px-4 py-2.5 rounded-lg border border-gray-200 shadow-sm bg-white transition-colors focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 focus:outline-none appearance-none"
            name="visibility"
          >
            <option value={-1}>Tous les articles</option>
            <option value={0}>Brouillon</option>
            <option value={1}>En attente de vérification</option>
            <option value={2}>En cours de vérification</option>
            <option value={3}>En ligne</option>
          </select>
        </div>
      </form>

      {/* Affichage des actions pour les articles sélectionnés */}
      {selectedArticles.length > 0 && (
        <div className="mb-4 p-3 bg-blue-100 rounded-lg">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
            <p className="text-blue-800 font-medium">{selectedArticles.length} article(s) sélectionné(s)</p>
            <div className="flex flex-wrap gap-2">
              <button
                onClick={handleCreatePressReview}
                disabled={hasDraftArticles}
                className={`px-3 py-1.5 text-white text-sm rounded-md shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 ${
                  hasDraftArticles 
                    ? 'bg-green-300 cursor-not-allowed' 
                    : 'bg-green-500 hover:bg-green-600'
                }`}
                title={hasDraftArticles ? "Impossible de créer une revue de presse avec des brouillons" : ""}
              >
                Créer une revue de presse
              </button>
              <button
                onClick={handleAddToPressReview}
                disabled={hasDraftArticles}
                className={`px-3 py-1.5 text-white text-sm rounded-md shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
                  hasDraftArticles 
                    ? 'bg-purple-300 cursor-not-allowed' 
                    : 'bg-purple-500 hover:bg-purple-600'
                }`}
                title={hasDraftArticles ? "Impossible d'ajouter des brouillons à une revue de presse" : ""}
              >
                Ajouter à une revue de presse
              </button>
              <button
                onClick={handleAddToFriendPressReview}
                disabled={hasDraftArticles}
                className={`px-3 py-1.5 text-white text-sm rounded-md shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                  hasDraftArticles 
                    ? 'bg-blue-300 cursor-not-allowed' 
                    : 'bg-blue-500 hover:bg-blue-600'
                }`}
                title={hasDraftArticles ? "Impossible d'ajouter des brouillons à une revue de presse" : ""}
              >
                <span className="hidden sm:inline">Ajouter à la revue de presse d'un ami</span>
                <span className="sm:hidden">Ajouter à revue d'ami</span>
              </button>
              <button
                onClick={handleBulkDelete}
                className="px-3 py-1.5 bg-red-500 hover:bg-red-600 text-white text-sm rounded-md shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Supprimer
              </button>
            </div>
          </div>
          {hasDraftArticles && (
            <p className="mt-2 text-red-600 text-sm">
              Certains articles sélectionnés sont des brouillons. Ils ne peuvent pas être utilisés pour créer ou être ajoutés à une revue de presse.
            </p>
          )}
        </div>
      )}

      <div className="md:block w-full shadow-md rounded-lg h-[calc(100vh-18rem)] overflow-y-auto">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              {/* Colonne pour la sélection */}
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input 
                  type="checkbox" 
                  checked={isCurrentPageAllSelected && articles.rows.length > 0}
                  onChange={handleSelectAllCurrentPage}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=title${searchTerm === '' ? '' : '&query='+searchTerm}`}>Titre{sortBy === 'title' && <ArrowDropUpIcon />}</a></th>
              <th className="hidden lg:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=created_at`}>Date de création{sortBy === 'created_at' && <ArrowDropUpIcon />}</a></th>
              <th className="hidden xl:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=updated_at`}>Date d'actualisation{sortBy === 'updated_at' && <ArrowDropUpIcon />}</a></th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=visible`}>Statut{sortBy === 'visible' && <ArrowDropUpIcon />}</a></th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {articles.rows.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                {/* Cellule avec la case à cocher pour la sélection */}
                <td className="px-4 py-4">
                  <input 
                    type="checkbox" 
                    checked={selectedArticles.some(article => article.id === item.id)}
                    onChange={() => handleSelectArticle(item)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </td>
                <td className="px-4 py-4 break-words">
                  <div className="max-w-xs lg:max-w-sm xl:max-w-md">{item.title}</div>
                </td>
                <td className="hidden lg:table-cell px-4 py-4">{formatDateExtended(item.created_at)}</td>
                <td className="hidden xl:table-cell px-4 py-4">{formatDateExtended(item.updated_at)}</td>
                <td className="px-4 py-4">{formatVisible(item.visible)}</td>
                <td className="px-4 py-4 whitespace-nowrap overflow-x-auto">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold rounded mr-1 p-1" onClick={() => handleViewArticle(item.id)}>
                <VisibilityIcon />
                </button>
                  <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded mr-1 p-1"
                    onClick={() => handleEdit(item.id)}
                  >
                    <EditIcon />
                  </button>
                  {
                    item.visible >= 1 && <button 
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold rounded mr-1 p-1"
                    title='Ajouter à une revue de presse'
                    onClick={() => {
                      const data = [{
                        country: countries.find((c) => c.code === user.country).name,
                        description: encodeHTML(item.description).slice(0,150),
                        image: '',
                        link: `https://static.zemus.info/article/?id=${item.id}`,
                        title: item.title
                      }]
                      window.location.href=`/addtoreview?data=${encodeURIComponent(JSON.stringify(data))}`
                    }}
                  >
                    <ArrowOutwardIcon />
                  </button>
                  }
                  {
                    item.visible >= 1 && <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded mr-1 p-1"
                    title="Ajouter à la revue de presse d'un de mes amis"
                    onClick={() => {
                      const data = [{
                        country: countries.find((c) => c.code === user.country).name,
                        description: encodeHTML(item.description).slice(0,150),
                        image: '',
                        link: `https://static.zemus.info/article/?id=${item.id}`,
                        title: item.title
                      }]
                      window.location.href=`/addtofriendreview?data=${encodeURIComponent(JSON.stringify(data))}`
                    }}
                  >
                    <PeopleIcon />
                    <ArrowOutwardIcon />
                  </button>
                  }
                  <button 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold rounded mr-1 p-1"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this item ?")) {
                        handleDelete(item);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={articles.count}
        paginate={paginate}
        currentPage={currentPage}
      />
      
    </div>
  );
}