import { useState } from "react";
import { tailwindClassForColoredButton } from "../../Helper/utils";
import { getUserIdFromEmail } from "../../HTTP/user";
import { addBeneficiairy, addNewBeneficiairy, fetchSubscriptionFromUserId } from "../../HTTP/subscription";
import { countries } from "../../global/countriesAndSources";

// Composant de popup pour ajouter un bénéficiaire
export default function AddBeneficiaryPopup({ isOpen, onClose, change, onChange }) {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1); // 1: email, 2: détails complémentaires
  const [userExists, setUserExists] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [beneficiairyId, setBeneficiairyId] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  // Fonction pour vérifier si un utilisateur existe déjà avec cet email
  const checkUserExists = async () => {
    if (!email || !email.includes("@")) {
      setError("Veuillez entrer une adresse email valide");
      return;
    }

    setLoading(true);
    setError("");

    const response = await getUserIdFromEmail(email);
    if (response) {
            setBeneficiairyId(response.id);
            setUserExists(true);
            // Si l'utilisateur existe, vérifier si il possède déjà un abonnement
            const abonnement = await fetchSubscriptionFromUserId(response.id);
            if (abonnement) {
                console.log(abonnement)
                if (abonnement.data === null) {
                    console.log("pas d'abonnement")
                    setAlreadySubscribed(false)
                } else {
                    setAlreadySubscribed(true)
                }
            } else {
                setAlreadySubscribed(false)
            }
            setStep(2);
        } else {
            setUserExists(false);
            setStep(2);
        }
    setLoading(false);
  };

  // Fonction pour ajouter un bénéficiaire
  const handleAddBeneficiary = async () => {
    if (userExists && !alreadySubscribed) {
      
      try {
        setLoading(true);
        
        // TODO: Remplacer par le vrai endpoint API
        const response = await addBeneficiairy(user.id, beneficiairyId);
        
        if (response) {
            console.log("Bénéficiaire ajouté avec succès");
          onClose();
        } else {
          setError("Erreur lors de l'ajout du bénéficiaire");
        }
      } catch (error) {
        console.error("Erreur lors de l'ajout du bénéficiaire:", error);
        setError("Une erreur est survenue lors de l'ajout du bénéficiaire");
      } finally {
        setLoading(false);
        onChange(!change);
        handleCancel();
      }
    } else {
      // Si l'utilisateur n'existe pas, on l'invite et on l'ajoute comme bénéficiaire
      if (!firstname || !lastname || !country) {
        setError("Veuillez remplir tous les champs");
        return;
      }

      try {
        setLoading(true);
        
        // TODO: Remplacer par le vrai endpoint API
        const response = await addNewBeneficiairy(firstname, lastname, email, country, user.id);
        
        if (response) {
          onClose();
        } else {
          setError("Erreur lors de l'ajout du bénéficiaire");
        }
      } catch (error) {
        console.error("Erreur lors de l'invitation du bénéficiaire:", error);
        setError("Une erreur est survenue lors de l'invitation du bénéficiaire");
      } finally {
        setLoading(false);
        onChange(!change);
        handleCancel();
      }
    }
  };

  const handleCancel = () => {
    // Réinitialisation des états
    setEmail("");
    setFirstname("");
    setLastname("");
    setCountry("");
    setError("");
    setStep(1);
    setUserExists(false);
    setAlreadySubscribed(false);
    setBeneficiairyId(null);
    setLoading(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4">
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-xl font-bold text-gray-800">
              Ajouter un bénéficiaire
            </h2>
            <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>

          <div className="p-4">
            {step === 1 && (
              <div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Adresse email du bénéficiaire
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="email@exemple.com"
                    disabled={loading}
                  />
                </div>

                {error && (
                  <div className="mb-4 p-2 bg-red-100 text-red-700 rounded-md">
                    {error}
                  </div>
                )}

                <div className="flex justify-between">
                  <button
                    onClick={handleCancel}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    disabled={loading}
                  >
                    Annuler
                  </button>
                  <button
                    onClick={checkUserExists}
                    className={`${tailwindClassForColoredButton("blue")} px-4 py-2`}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                        Vérification...
                      </div>
                    ) : (
                      "Continuer"
                    )}
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                {userExists && alreadySubscribed ? (
                    <div className="mb-4 p-3 bg-yellow-100 text-yellow-700 rounded-md">
                        Cet utilisateur est déjà abonné.
                    </div>
                ) : userExists ? (
                    <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
                        Cet utilisateur existe déjà. Vous pouvez l'ajouter comme bénéficiaire.
                    </div>
                ) : (
                  <>
                    <div className="mb-4 p-3 bg-yellow-100 text-yellow-700 rounded-md">
                      Cet utilisateur n'existe pas encore. Veuillez fournir des informations supplémentaires pour l'inviter.
                    </div>
                    
                    <div className="mb-4">
                      <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-1">
                        Prénom
                      </label>
                      <input
                        id="firstname"
                        type="text"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={loading}
                      />
                    </div>
                    
                    <div className="mb-4">
                      <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-1">
                        Nom
                      </label>
                      <input
                        id="lastname"
                        type="text"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={loading}
                      />
                    </div>
                    
                    <div className="mb-4">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                            Pays
                        </label>
                        <select onChange={(e) => setCountry(e.target.value)} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="country">
                        {
                            countries.map((c, i) => <option value={c.code} key={i}>{c.name}</option>)
                        }
                        </select>
                    </div>
                  </>
                )}

                {error && (
                  <div className="mb-4 p-2 bg-red-100 text-red-700 rounded-md">
                    {error}
                  </div>
                )}

                <div className="flex justify-between">
                  <button
                    onClick={() => setStep(1)}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    disabled={loading}
                  >
                    Retour
                  </button>
                  {
                    !alreadySubscribed ? (
                        <button
                            onClick={handleAddBeneficiary}
                            className={`${tailwindClassForColoredButton("green")} px-4 py-2`}
                            disabled={loading}
                        >
                            {loading ? (
                            <div className="flex items-center">
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                                Traitement...
                            </div>
                            ) : (
                            userExists ? "Ajouter comme bénéficiaire" : "Inviter et ajouter"
                            )}
                        </button>
                    ) : null
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}