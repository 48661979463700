import React, { useState, useEffect } from "react";
import "./articles.css";
import Favo from "../../media/fav.jpeg"; 
import { removeFavorite, createReview, fetchLinkedReview, getAIReviewThemeAndPresentation } from "../../HTTP/articles" 
import * as helpers from "../../Helper/utils"; // Importez tout le module helper
import userApi from "../../HTTP/user"
import { languages } from "../../global/countriesAndSources";

const ArticlesFav = () => {
  if (localStorage.getItem("subscriptions") === null) {
    window.location.href = '/'
  } else {
    if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
      window.location.href = '/'
    }
  }
  const user = JSON.parse(localStorage.getItem('user'))
  const favorites = JSON.parse(localStorage.getItem("favorites"))
  const [showPopup, setShowPopup] = useState(false);
  const [showAIOptions, setShowAIOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('currentlanguage') || "fr");
  const [selectedTone, setSelectedTone] = useState("synthétique");
  const [error, setError] = useState("");
  const [selection, setSelection] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [aiGenerated, setAiGenerated] = useState(false);
  
  const toneOptions = [
    "synthétique",
    "journalistique",
    "pédagogique",
    "académique",
    "diplomatique",
    "narratif",
    "littéraire",
    "humoristique", 
    "polémique",
    "engagé/militant"
  ];
  
  const languageOptions = [
    "français",
    "anglais",
    "espagnol",
    "allemand",
    "italien",
    "portugais"
  ];
  
  useEffect(() => {
    const fetchReviews = async () => {
      try{
        const reviewsData = await userApi.getUserReviews(); // Assurez-vous que ceci renvoie un tableau
        console.log(reviewsData.reviews); // Debug: Qu'affiche cela ?
        setReviews(reviewsData.reviews);
      }
      catch{
        console.log('no reviews')
      }
    };
    
    fetchReviews();
  }, []);
 
  // Fonction pour mettre à jour la sélection
  const updateSelection = () => {
    let favs = [];
    const checkedCheckboxes = document.querySelectorAll('.itemfav input[type="checkbox"]:checked');
    checkedCheckboxes.forEach(checkbox => {
      let parentItem = checkbox.closest('.itemfav');
      if (parentItem) {
        let dataId = parentItem.getAttribute('data-id');
        if (dataId) {
          favs.push(favorites[dataId]);
        }
      }
    });
    setSelection(favs);
    console.log(selection)
  };
  
  const togglePopup = () => {
    updateSelection();
    setShowPopup(!showPopup);
  };

  const toggleAIOptions = () => {
    setShowAIOptions(!showAIOptions);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFavorites = favorites.filter(fav => 
    fav.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.country.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  function removeFav(i) {
    const favorite = {
      link: helpers.htmlDecode(favorites[i].link)
    }
    removeFavorite(favorite).then((res) => {
        console.log(res) 
        document.getElementById("fav_"+i).remove();
        favorites.splice(i, 1); 
        localStorage.setItem('favorites', JSON.stringify(favorites)); 
    })
    alert('Deleted favorite successfully');
  } 
 
  const addReview= () => {
    const theme = document.getElementById('review_theme').value
    const presentation = document.getElementById('review_presentation').value
    createReview(theme, presentation, selection, !aiGenerated ? document.getElementById('publishable_review').checked : 0).then((res) => {
      console.log(res) 
      setShowPopup(!showPopup);
      alert('Review created successfully');
      window.location.href = `https://static.zemus.info/?id=${res.newReview}&redirect=favourites`; 
    }) 
  }

  const generateWithAI = async () => {
    // Vérifier si des articles sont sélectionnés
    if (selection.length === 0) {
      alert("Veuillez sélectionner au moins un article avant de générer du contenu.");
      return;
    }

    // Activer l'indicateur de chargement
    setIsLoading(true);
    
    // Récupérer les liens des articles sélectionnés
    const articleLinks = selection.map(item => helpers.htmlDecode(item.link));
    
    try {
      // Appel à la fonction d'IA
      const result = await getAIReviewThemeAndPresentation(
        selectedLanguage,
        selectedTone,
        articleLinks,
        user.id, 
      );
      
      // Si la génération est réussie, remplir les champs
      if (result && result.theme) {
        document.getElementById('review_theme').value = result.theme;
      }
      
      if (result && result.presentation) {
        document.getElementById('review_presentation').value = result.presentation.join('\n\n');
      }
      
      // Masquer les options d'IA après avoir généré
      setShowAIOptions(false);

      // Marquer l'indicateur de génération avec l'IA comme vrai
      setAiGenerated(true);
      
    } catch (error) {
      console.error("Erreur lors de la génération avec l'IA:", error);
      alert("Une erreur s'est produite lors de la génération avec l'IA.");
    } finally {
      // Désactiver l'indicateur de chargement quoi qu'il arrive
      setIsLoading(false);
    }
  };

  const addToExistingReview = () => { 
      const jsonString = JSON.stringify(selection) 
      const encodedString = encodeURIComponent(jsonString) 
      window.location.href = `/addtoreview?data=${encodedString}`
  }

  const addToExistingFriendReview = () => { 
    const jsonString = JSON.stringify(selection) 
    const encodedString = encodeURIComponent(jsonString) 
    window.location.href = `/addtofriendreview?data=${encodedString}`
  }
  
  // Composant pour l'indicateur de chargement
  const LoadingSpinner = () => (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
      <p className="loading-text">Génération en cours...</p>
    </div>
  );

  return (
      <> 
            { showPopup && <div className="blur-background"></div> }
            { showPopup && (
              <>
                <div className="popup"> 
                  <div style={{ display: "flex" }} className="headtitle">
                    <h2>Créer une revue de Presse</h2>
                    <span onClick={togglePopup} className="material-symbols-outlined">
                      close
                    </span>
                  </div>
                  <div>
                    <div className="Message">
                      <input
                        title="Write Message"
                        name="theme"
                        placeholder="Thème..."
                        className="MsgInput"
                        type="text"
                        maxLength="255"
                        id="review_theme"
                      /> 
                    </div>
                    <div className="Message">
                      <textarea
                        name="presentation"
                        placeholder="Description..."
                        className="MsgInput"
                        rows={4}
                        maxLength="255"
                        id="review_presentation"
                      /> 
                    </div>
                    {
                      !aiGenerated && (
                        <div className="Message">
                        <input
                          name="publishable_review"
                          type="checkbox"
                          maxLength="255"
                          id="publishable_review"
                        /> 
                        <label htmlFor="publishable_review" className="text-white ml-2">
                          Autoriser mes amis à publier leurs propres favoris dans cette revue de presse
                        </label>
                      </div>
                      )
                    }
                    
                    {/* Bouton pour afficher les options de l'IA */}
                    <div className="Message">
                      {
                        !aiGenerated && (
                          <button 
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded mr-2"
                            onClick={toggleAIOptions}
                            disabled={isLoading}
                          >
                            {showAIOptions ? "Masquer les options d'IA" : "Remplir grâce à l'IA"}
                          </button>
                        )
                      }
                      
                      <button 
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-5 rounded"
                        onClick={addReview}
                        disabled={isLoading}
                      >
                        Valider
                      </button>
                    </div>
                    
                    {/* Options de l'IA (conditionnellement affichées) */}
                    {showAIOptions && (
                      <div className="ai-options p-3 border border-gray-300 rounded mt-3 mb-3">
                        <div className="flex flex-col sm:flex-row gap-3 mb-3">
                          <div className="flex-1">
                            <label htmlFor="language-select" className="block text-white mb-1">
                              Langue:
                            </label>
                            <select
                              id="language-select"
                              className="w-full p-2 rounded"
                              value={selectedLanguage}
                              onChange={(e) => setSelectedLanguage(e.target.value)}
                              disabled={isLoading}
                            >
                              {languages.map((lang) => (
                                <option key={lang.language_code} value={lang.language_code}>
                                  {lang.language_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          
                          <div className="flex-1">
                            <label htmlFor="tone-select" className="block text-white mb-1">
                              Ton journalistique:
                            </label>
                            <select
                              id="tone-select"
                              className="w-full p-2 rounded"
                              value={selectedTone}
                              onChange={(e) => setSelectedTone(e.target.value)}
                              disabled={isLoading}
                            >
                              {toneOptions.map((tone) => (
                                <option key={tone} value={tone}>
                                  {tone.charAt(0).toUpperCase() + tone.slice(1)}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        
                        <button
                          className={`text-white font-bold py-2 px-5 rounded w-full flex justify-center items-center ${isLoading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-700'}`}
                          onClick={generateWithAI}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <div className="flex items-center">
                              <div className="animate-spin h-5 w-5 mr-2 border-t-2 border-b-2 border-white rounded-full"></div>
                              Génération en cours...
                            </div>
                          ) : (
                            'Générer'
                          )}
                        </button>
                      </div>
                    )}
                    
                    {/* Indicateur de chargement global sur la popup */}
                    {isLoading && (
                      <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white p-5 rounded-lg shadow-lg flex flex-col items-center">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 rounded-full animate-spin mb-3" style={{ borderTopColor: '#3B82F6' }}></div>
                          <p className="text-gray-700 font-medium">Génération en cours...</p>
                          <p className="text-gray-500 text-sm mt-1">Cela peut prendre un moment</p>
                        </div>
                      </div>
                    )}
                    
                    <div className="Message">
                    {
                      selection.map((fav, i) => (
                        <img
                            key={i}
                            className="item-photofav-add"
                            src={ helpers.htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                            alt="article"  
                          />
                      ))
                    }
                    </div>
                  </div>
                </div>
              </>
            )}                 

        <section className="articlesfav">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Rechercher parmi les favoris..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
        </div>
            { 
              (() => {
                if (error.length > 0){
                  return <h1>{error}</h1>
                }
              })
              ()
            } 

            <div className="titleDivfav" style={{ display: "flex", marginTop: 55, marginBottom: 35}}>
                <button onClick={togglePopup} className="mx-1">Créer une revue de presse</button>
                <button onClick={addToExistingReview} className="mx-1">Ajouter à une revue de presse</button> 
                <button onClick={addToExistingFriendReview} className="mx-1">Ajouter à une revue de presse d'un ami</button> 
            </div>

            {
              filteredFavorites.map((fav, i) => (
                    <div className="featuresfav" key={i} id={`fav_${i}`}>
                        <div className="itemfav rounded-lg shadow m-2" data-id={i}>
                          <div  className="item-photowrapper">
                              <img
                                  className="item-photofav"
                                  src={helpers.htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                                  alt="article"
                                  onClick={() => window.open(helpers.htmlDecode(fav.link), "_blank")} 
                                />
                          </div>
                          <div className="arttfav">
                            <div className="item-headerfav">
                              
                            </div>
                            <a href={helpers.htmlDecode(fav.link)} target="_blank" rel="noreferrer" style={{ textDecoration: "inherit", color: "inherit" }}>
                              <h3 className="item-titlefav">
                                  {helpers.htmlDecode(fav.title) }
                              </h3>
                              <p><strong className="text-cyan-600">
                                  Pays : {helpers.htmlDecode(fav.country) } | Source : {helpers.getDomainName(fav.link) } 
                                </strong></p>  
                              <p className="item-textfav">
                                  {helpers.htmlDecode(fav.description) }
                              </p>
                            </a>
                            {/* <a className="item-link" href="#">Click to see by yourself &rarr;</a> */}
                            <div>
                              <div  className="inline-block mb-2">Présent dans : </div>
                            {Array.isArray(reviews) ? reviews.map(review => (
                                review.articles.filter(article => article.title === fav.title)
                                .map(filteredArticle => ( 
                                  <div key={filteredArticle.id} className="inline-block">
                                    <strong>{review.theme}</strong><span className="mx-1"> | </span>
                                  </div>
                                ))
                              )) : null} 
                            </div>

                          </div>

                          { true ? 
                          (
                            <div className="actiondiv">
                              <input type="checkbox" onClick={updateSelection} />
                              <span onClick={() => removeFav(i)} className="material-symbols-outlined" >
                                  delete
                              </span>
                            </div>
                          ) : 
                          (
                            <div className="favicon"><img src={Favo} alt="fav icon"/></div>
                          )
                          }
                        </div>
                    </div>
              ))
            }

        </section>

        {/* Ajout des styles CSS pour l'animation du chargement */}
        <style jsx>{`
          .loading-spinner-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;
          }
          
          .loading-spinner {
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            border-top: 4px solid #3B82F6;
            width: 2rem;
            height: 2rem;
            animation: spin 1s linear infinite;
            margin-bottom: 0.5rem;
          }
          
          .loading-text {
            color: white;
            font-size: 0.875rem;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </>
  );
};

export default ArticlesFav;