import authHeader from "./auth.header.js"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

export async function fetchSubscriptionFromUserId(userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/user/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to fetch subscription');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching subscription:', error);
        throw error;
    }
}

export async function fetchBeneficiairyUsers(userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/users/${userId}/beneficiairy`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to fetch beneficiairy users');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching beneficiairy users:', error);
        throw error;
    }
}

export async function countMaxPeopleInSubscription(userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/users/${userId}/beneficiairy/max`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to count max people in subscription');
        }

        return await response.json();
    } catch (error) {
        console.error('Error counting max people in subscription:', error);
        throw error;
    }
}

export async function countRemainingBeneficiairy(userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/users/${userId}/beneficiairy/count`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to count remaining beneficiairy');
        }

        return await response.json();
    } catch (error) {
        console.error('Error counting remaining beneficiairy:', error);
        throw error;
    }
}

export async function deleteBeneficiairy(subscriptionId, giverUserId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/users/beneficiairy/remove`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify({ subscription_id: subscriptionId, giver_user_id: giverUserId })
        });

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to delete beneficiairy');
        }

        return true;
    } catch (error) {
        console.error('Error deleting beneficiairy:', error);
        throw error;
    }
}

export async function cancelSubscription(userId) {
    try {
        const response = await fetch(`${HOST_URL}/payment/subscriptions/cancel/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        });

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to cancel subscription');
        }

        return true;
    } catch (error) {
        console.error('Error cancelling subscription:', error);
        throw error;
    }
}

export async function addBeneficiairy(userId, beneficiairyId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/beneficiairy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify({ user_id: userId, linked_subscription_user_id: beneficiairyId })
        });

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to add beneficiairy');
        }

        return true;
    } catch (error) {
        console.error('Error adding beneficiairy:', error);
        throw error;
    }
}

export async function addNewBeneficiairy(firstname, lastname, email, country, userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/beneficiairy/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify({ firstname, lastname, email, country, giver_user_id: userId })
        });

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to add beneficiairy');
        }

        return true;
    } catch (error) {
        console.error('Error adding beneficiairy:', error);
        throw error;
    }
}