import React, { useEffect, useState } from "react";
import "./Feed.css";
import "../Home/Home.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import pin from "../../media/pin.png";
import smile from "../../media/smile.png";
import favoris from "../../media/favoris.png";
import imgArticle from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import { addFavorite, fetchAllReviews, getArticles, getFeedArticles, getFeedReviews, getFriendsArticles, getFriendsReviews, removeFavorite } from "../../HTTP/articles";
import { formatDateExtended, removeHtmlTags } from "../../Helper/utils";
import Pagination from "../../Helper/Pagination";
import { ReviewBlock } from "../Review/ReviewBlock";
import { use } from "react";
import ReviewBlocks from "../Review/ReviewBlocks";
//import DropDownMenuPrivacy from '../DropDownMenus/DropDownMenuPrivacy'; // Assurez-vous que le chemin est correct

const Feed = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const url = new URL(window.location.href)
      if (url.searchParams.get('articlepage') === null) {
          url.searchParams.set('articlepage', 1);
          window.history.pushState({}, '', url);
      }
      if (url.searchParams.get('reviewpage') === null) {
        url.searchParams.set('reviewpage', 1);
        window.history.pushState({}, '', url);
    }
  const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
  const [launchSearch, setLaunchSearch] = useState(false);
  const [currentArticlePage, setCurrentArticlePage] = useState(parseInt(url.searchParams.get('articlepage')));
  const [currentReviewPage, setCurrentReviewPage] = useState(parseInt(url.searchParams.get('reviewpage')));
  const [articlesPerPage] = useState(4);
  const [reviewsPerPage] = useState(3);
  const [articles, setArticles] = useState({count:0, rows:[]});
  const [articleReady, setArticleReady] = useState(false);
  const [reviews, setReviews] = useState({count:0, rows:[]});
  const [reviewsReady, setReviewsReady] = useState(false);
  const [showArticles, setShowArticles] = useState(false);
  const isUserSubscribed = JSON.parse(localStorage.getItem("subscriptions")) !== null

  useEffect(() => {
    setArticleReady(false)
    const funArticles = async () => {
      const resArticles = isUserSubscribed ? await getFeedArticles(user.id, (currentArticlePage - 1) * articlesPerPage, currentArticlePage * articlesPerPage, searchTerm) : await getFriendsArticles(user.id, (currentArticlePage - 1) * articlesPerPage, currentArticlePage * articlesPerPage, searchTerm)
      setArticles(resArticles)
    }
    setReviewsReady(false)
    const funReviews = async () => {
      const resReviews = isUserSubscribed ? await getFeedReviews(user.id, (currentReviewPage - 1) * reviewsPerPage, currentReviewPage * reviewsPerPage, searchTerm) : await getFriendsReviews(user.id, (currentReviewPage - 1) * reviewsPerPage, currentReviewPage * reviewsPerPage, searchTerm)
      setReviews(resReviews)
    }
    funArticles()
    funReviews()
    setArticleReady(true)
    setReviewsReady(true)
    setLaunchSearch(false)
  }, [currentArticlePage, currentReviewPage, launchSearch])

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the search term (e.g., make an API call)
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    if (url.searchParams.get('query') === null) {
      url.searchParams.set('query', searchTerm);
    } else {
      url.searchParams.set('query', searchTerm);
    }
    window.history.pushState({}, '', url);
    setLaunchSearch(true);
  };

  const articlePaginate = (pageNumber) => {
    url.searchParams.set('articlepage', pageNumber);
    window.history.pushState({}, '', url);
    setCurrentArticlePage(pageNumber);
  };

  const reviewPaginate = (pageNumber) => {
    url.searchParams.set('reviewpage', pageNumber);
    window.history.pushState({}, '', url);
    setCurrentReviewPage(pageNumber);
  };

  const [favorites, setFavorites] = useState(JSON.parse(localStorage.getItem('favorites') || []));

  const handleArticleToFavorite = async (title, link, image, country, date, description) => {
    const favorite = {
      title: title,
      link: link,
      image: image || "",
      country: user.country,
      publication_date: date,
      description: description
    };

    try {
      const res = await addFavorite(favorite);
      if (res.ok === true) {
        favorite.id = res.id;
        const updatedFavorites = [...favorites, favorite];
        setFavorites(updatedFavorites);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
        alert('Article added to favorites !');
      }
    } catch (error) {
      console.error('An error has occured when adding the favorite', error);
      alert('An error has occured when adding the favorite');
    }
  };

  const handleRemoveFromFavorite = async (link) => {
    try {
      const res = await removeFavorite({ link });
      if (res) {
        console.log(favorites)
        const updatedFavorites = favorites.filter(fav => fav.link !== link);
        console.log(updatedFavorites)
        setFavorites(updatedFavorites);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
        alert('Article removed from favorites !');
      }
    } catch (error) {
      console.error('An error has occured when deleting the favorite', error);
      alert('An error has occured when deleting the favorite');
    }
  };

  const handleDisplayAddFavoriteButton = (link) => {
    if (JSON.parse(localStorage.getItem("favorites")).find((f) => f.link === link) === undefined) {
      return true;
    }
    return false;
  }

  const ArticleCard = ({ articleId, imageUrl, author, authorProfilePicture, authorId, title, text, date }) => {
    const articleLink = `https://static.zemus.info/article/?id=${articleId}`;
    const isFavorite = favorites.some(fav => fav.link === articleLink);

    const handleFavoriteClick = () => {
      if (isFavorite) {
        handleRemoveFromFavorite(articleLink);
      } else {
        handleArticleToFavorite(
          title,
          articleLink,
          imageUrl,
          user.country,
          new Date().toISOString().replace("T", " ").slice(0, 19),
          text.length > 150 ? text.slice(0, 50) + "..." : text
        );
      }
    };

    return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl h-full">
      <div className="flex flex-col h-full">
      <a href={`https://static.zemus.info/article/?id=${articleId}`}>
        <img
          className="w-full h-48 object-cover flex-shrink-0"
          src={imageUrl === null ? imgArticle : imageUrl}
          alt={title}
        />
      </a>
        <div className="p-4 flex-grow flex flex-col">
          <a href={`https://static.zemus.info/article/?id=${articleId}`}>
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            <p className="text-gray-600 flex-grow">
              {text.length > 150 ? text.slice(0, 50) + "..." : text}
            </p>
          </a>
          <div className="flex items-center mb-2">
            <a href={`/friends/${authorId}`} className="flex items-center space-x-2">
              <img
                className="w-8 h-8 rounded-full mr-2"
                src={authorProfilePicture || process.env.PUBLIC_URL+"/profile_default.png"}
                alt="profile_picture_author"
              />
              <p className="text-sm mt-2 notranslate">
                {author}
              </p>
            </a>
            <p className="text-sm mt-2 ml-2 notranslate">
              {formatDateExtended(date)}
            </p>
            <button 
              onClick={handleFavoriteClick}
              className="ml-auto focus:outline-none"
            >
            <img 
              className="w-6 h-6 ml-2" 
              src={process.env.PUBLIC_URL + (isFavorite ? "/bookmark.svg" : "/bookmarklight.svg")} 
              alt="favoris"
            />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

  console.log(articles)
  console.log(reviews)
  
  return (
    <div className="mt-8">
      <form onSubmit={handleSearch} className="flex w-full items-center mb-4">
        <div className="flex-grow ml-4 mr-2 mt-3">
          <input
            type="text"
            placeholder="Rechercher..."
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold mt-3 mr-4 py-2 px-4 rounded-md whitespace-nowrap"
        >
          <SearchIcon />
        </button>
      </form>
      <div className="w-full flex justify-center md:flex-row flex-col p-2">
        <button
          type="button"
          style={{ backgroundColor: showArticles === false ? '#b4f6ff' : "#009aad", color: showArticles === false ? "black" : "white"}}
          className="w-full md:mr-4 mb-4 md:mb-0 p-2 shadow-sm transition-colors rounded"
          onClick={() => setShowArticles(false)}
          >
            Revues de presse
        </button>
        <button
          type="button"
          style={{ backgroundColor: showArticles === true ? '#b4f6ff' : "#009aad", color: showArticles === true ? "black" : "white" }}
          className="w-full p-2 shadow-sm transition-colors rounded"
          onClick={() => setShowArticles(true)}
          >
            Articles				
        </button>
      </div>
      {
        showArticles && (
          <>
          <div className="flex justify-center text-center mb-4">
            <h2 className="text-2xl font-bold">{isUserSubscribed ? "Derniers articles publiés" : "Derniers articles publiés par mes amis"}</h2>
          </div>
            {
              articleReady && articles.count === 0 && (
                <div className="flex justify-center text-center mb-4">
                  <h2 className="text-2xl font-bold">Aucun article trouvé</h2>
                </div>
              )
            }
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 ml-4 mr-4">
            {
              articleReady && articles.count > 0 && articles.rows.map((item, index) => (
                  <ArticleCard
                    articleId={item.id}
                    imageUrl={item.featured_image}
                    author={item.firstname + " " + item.lastname}
                    authorProfilePicture={item.profile_picture}
                    authorId={item.user_id}
                    title={item.title}
                    text={removeHtmlTags(item.description)}
                    date={item.updated_at}
                  />
              ))
            }
          </div>
          <Pagination
            itemsPerPage={articlesPerPage}
            totalItems={articles.count}
            paginate={articlePaginate}
            currentPage={currentArticlePage}
          />
          </>
        )
      }
      {
        !showArticles && (
          <>
          <div className="flex justify-center text-center mb-4">
            <h2 className="mt-2 text-2xl font-bold">{isUserSubscribed ? "Dernières revues de presse publiées" :"Dernières revues de presse publiées par mes amis"}</h2>
          </div>
            {
              reviewsReady && reviews.count === 0 && (
                <div className="flex justify-center text-center mb-4">
                  <h2 className="text-2xl font-bold">Aucune revue de presse trouvée</h2>
                </div>
              )
            }
            {
              reviewsReady && reviews.count > 0 && 
              <ReviewBlocks reviews={reviews.rows} edition="false" displayAuthor={true} favorites={favorites} handleAddFav={handleArticleToFavorite} handleRemFav={handleRemoveFromFavorite} />
            }
          
          <div className="mb-4">
          <Pagination
            itemsPerPage={reviewsPerPage}
            totalItems={reviews.count}
            paginate={reviewPaginate}
            currentPage={currentReviewPage}
          />
        </div>
        </>
        )
      }
      
    </div>
  );
};

export default Feed;
