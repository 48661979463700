import { useEffect, useState } from "react"
import { fetchSubscriptionFromUserId } from "../../HTTP/subscription"
import { formatDateExtended, tailwindClassForColoredButton } from "../../Helper/utils"
import { deletePriceSubscription, deleteSubscription, fetchAllPriceSubscription, fetchSubscriptionFromAllUsers, getUserById } from "../../HTTP/admin"
import TableElements from "./TableElements"
import SideBarAdmin from "./SideBarAdmin"
import ListElementsMobile from "./ListElementsMobile"

export default function AdminPriceSubscription() {

    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const [priceSubscriptions, setPriceSubscriptions] = useState([])
    const [readyPrices, setReadyPrices] = useState(false)

    useEffect(() => {
        const funGetPriceSubscription = async () => {
            const resPriceSubscriptions = await fetchAllPriceSubscription()
            console.log(resPriceSubscriptions)
            setPriceSubscriptions(resPriceSubscriptions)
        }
        funGetPriceSubscription()
        setReadyPrices(true)
    }, [])

    const handleDeletePriceSub = async (id) => {
        if (window.confirm("Are you sure you want to delete this subscription price?")) {
            const res = await deletePriceSubscription(id)
            window.location.reload()
        }
    }

    const handleEditPriceSub = (id) => {
        window.location.href = "/admin/subscription/prices/edit/"+id
    }

    const handleAddPriceSub = () => {
        window.location.href = "/admin/subscription/prices/add"
    }

    return (
        <>
    <div className="flex mt-8">
        <div className="flex-grow">
        <h1 className="text-center mt-4 text-2xl font-bold">
            Gestion prix abonnement
        </h1>
        <button
            className={tailwindClassForColoredButton("green") + " mt-4 mb-4 ml-8"}
            onClick={() => handleAddPriceSub()}
        >
            Ajouter un tarif d'abonnement
        </button>
        {
            readyPrices && (
                <>
                <div className="hidden xl:block ml-8 mr-8">
                    <TableElements 
                        columns={[
                            {props:'title',display:"Titre"}, 
                            {props:'price',display:"Prix"}, 
                            {props:'currency',display:"Devise"},
                            {props:'interval',display:"Intervalle", format:'intervalSub'},
                            {props:'max_people',display:"Nombre de personnes max"}, 
                            {props:'',display:"Actions"}
                        ]}
                        data={priceSubscriptions}
                        buttons={[{display:'Modifier',action:handleEditPriceSub, color:'blue', props:'id'}, {display:'Supprimer',action:handleDeletePriceSub, color:'red', props:'id'},]}
                        />
                </div>
                <div className="xl:hidden w-full px-4 space-y-4">
                    <ListElementsMobile
                        columns={[
                            {props:'title',display:"Titre"}, 
                            {props:'price',display:"Prix"}, 
                            {props:'currency',display:"Devise"},
                            {props:'interval',display:"Intervalle", format:'intervalSub'}, 
                            {props:'',display:"Actions"} 
                        ]}
                        data={priceSubscriptions}
                        buttons={[{display:'Éditer',action:handleEditPriceSub, color:'blue', props:'id'}, 
                            {display:'Supprimer',action:handleDeletePriceSub, color:'red', props:'id'}]}
                    />
                </div>
                </>
            )
        }
        </div>
    </div>
    </>
    )
}