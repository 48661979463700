import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { addSubscription, fetchAllPriceSubscription, fetchSubscriptionFromId, getAllUsers, getNumberOfUsers, updateSubscription } from "../../HTTP/admin";
import { formatDateExtended } from "../../Helper/utils";
import user from "../../HTTP/user";

export default function EditSubscription() {

  const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const [nbUsers, setNbUsers] = useState(0);
    const [users, setUsers] = useState([])
    const [prices, setPrices] = useState([])
    const [searchDone, setSearchDone] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        const funNumberUsers = async () => {
                const numberUsers = await getNumberOfUsers('ac')
                setNbUsers(numberUsers)
        }
        funNumberUsers()
    }, [])

    useEffect(() => {
        const funSearchUsers = async () => {
            const searchUsers = await getAllUsers(0, nbUsers, 'ac')
            setUsers(searchUsers)
        }
        funSearchUsers()
    }, [nbUsers])

    useEffect(() => {
      const fetchPrices = async () => {
        const resFetchPrices = await fetchAllPriceSubscription();
        setPrices(resFetchPrices);
      }
      fetchPrices();
      setSearchDone(true)
  }, []);

    const [formData, setFormData] = useState({
        user_id: '',
        start_date: '',
        end_date: '',
      });

      useEffect(() => {
        const funSearchSubscription = async () => {
          const searchSubscription = await fetchSubscriptionFromId(id)
          setFormData(searchSubscription)
        }
        funSearchSubscription()
      }, [id])

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
        console.log(formData)
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = {
          id: parseInt(id),
          user_id: formData.user_id,
          start_date : formData.start_date,
          end_date : formData.end_date,
        }
        try {
          const res = await updateSubscription(request)
          alert("Subscription updated successfully");
          window.location.href = "/admin/subscription";
        } catch (err) {
          console.log(err)
          alert("An error occured while saving the subscription");
        }
    }

    console.log(formData)

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Abonnement</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
                Utilisateur
              </label>
              <select value={formData.user_id} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="user_id">
                  {
                     users.map((u, i) => <option value={u.id} key={i}>{u.firstname} {u.lastname}</option>)
                  }
                </select>
            </div>
            <div className="mb-4">
              <label htmlFor="start_date" className="block text-gray-700 text-sm font-bold mb-2">
                Date de début d'abonnement
                <input type="datetime-local" value={formData.start_date} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="start_date"/>
              </label>
              
            </div>
            <div className="mb-4">
              <label htmlFor="end_date" className="block text-gray-700 text-sm font-bold mb-2">
                Date de fin d'abonnement
                <input type="datetime-local" value={formData.end_date} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="end_date"/>
              </label>
              
            </div>
            <div className="flex items-center justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Soumettre
              </button>
            </div>
          </form>
        </div>
    )
}