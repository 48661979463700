import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { createArticle, getArticleById, getFeaturedImage, updateArticle, uploadFeaturedImage } from "../../HTTP/articles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { encodeHTML, escapeQuotes } from "../../Helper/utils";
import { languages } from "../../global/countriesAndSources";
import { getAllLanguagesFromCountry } from "../../HTTP/translation";

export default function MyArticlesEdit() {
    if (localStorage.getItem("subscriptions") === null) {
        window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }

    const user = JSON.parse(localStorage.getItem("user"))
    const { id } = useParams()
    const [formData, setFormData] = useState({
        id: id,
        user_id: user.id,
        title: '',
        description: '',
        language: '',
        image: null,
        imagePreview: '',
        currentImage: null,
        visible: 0,
        copyright: true // Pour une image existante, on considère que les droits sont acquis
    });
    const [languagesOfUser, setLanguagesOfUser] = useState([])
    const [listLang, setListLang] = useState([])

    useEffect(() => {
        const funGetLanguages = async () => {
            const langFromUser = await getAllLanguagesFromCountry(user.country)
            setLanguagesOfUser(langFromUser)
        }
        funGetLanguages()
    }, [])

    useEffect(() => {
        const langList = languages.filter(lang => languagesOfUser.includes(lang.language_code))
        setListLang(langList)
        setFormData(prevData => ({
            ...prevData,
            language: languagesOfUser[0]
        }))
    }, [languagesOfUser])

    useEffect(() => {
        const fetchArticleData = async () => {
            try {
                const resArticle = await getArticleById(id);
                
                if (resArticle.featured_image) {
                    const imageData = await getFeaturedImage(id);
                    setFormData(prevData => ({
                        ...prevData,
                        ...resArticle,
                        currentImage: imageData,
                        imagePreview: URL.createObjectURL(imageData),
                        copyright: true // L'image existante est considérée comme ayant les droits
                    }));
                } else {
                    setFormData(prevData => ({
                        ...prevData,
                        ...resArticle
                    }));
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
                alert("Erreur lors de la récupération des données de l'article");
            }
        };

        fetchArticleData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        
        if (type === 'file') {
            const file = files[0];
            if (file) {
                if (!file.type.startsWith('image/')) {
                    alert('Veuillez sélectionner une image valide');
                    return;
                }
                
                if (file.size > 5 * 1024 * 1024) {
                    alert('L\'image ne doit pas dépasser 5MB');
                    return;
                }
                
                if (formData.imagePreview && formData.imagePreview !== formData.currentImage) {
                    URL.revokeObjectURL(formData.imagePreview);
                }
                
                setFormData(prevData => ({
                    ...prevData,
                    image: file,
                    imagePreview: URL.createObjectURL(file),
                    copyright: false // Réinitialise le copyright pour la nouvelle image
                }));
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    // Nouvelle fonction pour supprimer l'image
    const handleRemoveImage = () => {
        if (formData.imagePreview) {
            URL.revokeObjectURL(formData.imagePreview);
        }
        
        setFormData(prevData => ({
            ...prevData,
            image: null,
            imagePreview: '',
            currentImage: null,
            copyright: true // Réinitialise le copyright car pas d'image
        }));
        
        const fileInput = document.getElementById('image');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    useEffect(() => {
        return () => {
            if (formData.imagePreview && formData.imagePreview !== formData.currentImage) {
                URL.revokeObjectURL(formData.imagePreview);
            }
        };
    }, [formData.imagePreview, formData.currentImage]);

    const handleSubimeChange = (content) => {
        setFormData(prevData => ({
            ...prevData,
            description: content
        }));
    }

    // Vérifie si le formulaire peut être soumis
    const isSubmitDisabled = (formData.image && !formData.copyright);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Si l'image a été supprimée, on l'indique dans la mise à jour
            const updateData = {
                id: formData.id,
                user_id: formData.user_id,
                title: formData.title,
                description: formData.description,
                language: formData.language,
                visible: formData.visible,
                remove_image: !formData.currentImage && !formData.image // Ajouter ce flag si nécessaire dans votre API
            };
            
            const res = await updateArticle(updateData);
            
            if (formData.image) {
                await uploadFeaturedImage(formData.id, formData.image);
                alert('Article mis à jour avec succès avec la nouvelle image');
            } else {
                alert('Article mis à jour avec succès');
            }
            
            window.location.href = '/myarticles';
        } catch (err) {
            console.error("Erreur lors de la mise à jour:", err);
            alert("Une erreur est survenue lors de la mise à jour de l'article");
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Édition de l'Article</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
                        Titre
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="language" className="block text-gray-700 text-sm font-bold mb-2">
                        Langue de l'article
                    </label>
                    <select 
                        value={formData.language} 
                        onChange={handleChange} 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                        name="language"
                    >
                        {languages.map((l, i) => (
                            <option value={l.language_code} key={i}>{l.language_name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="image" className="block text-gray-700 text-sm font-bold mb-2">
                        Image à la une
                    </label>
                    <div className="flex gap-2">
                        <input
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {(formData.image || formData.currentImage) && (
                            <button
                                type="button"
                                onClick={handleRemoveImage}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Supprimer
                            </button>
                        )}
                    </div>
                    {formData.imagePreview && (
                        <div className="mt-2">
                            <img
                                src={formData.imagePreview}
                                alt="Preview"
                                className="max-w-full h-auto rounded-md"
                            />
                        </div>
                    )}
                </div>
                {(formData.image || formData.currentImage) && (
                    <div className="mb-6">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="copyright"
                                checked={formData.copyright}
                                onChange={handleChange}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Je suis l'auteur de l'image à la une ou j'ai les droits d'utilisation</span>
                        </label>
                    </div>
                )}
                <div className="mb-4">
                    <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
                        Description
                    </label>
                    <ReactQuill
                        theme="snow"
                        value={formData.description}
                        onChange={handleSubimeChange}
                        className="notranslate"
                    />
                </div>
                <div className="mb-6">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="visible"
                            checked={formData.visible}
                            onChange={handleChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">Rendre public l'article (sera modéré par les équipes de Zemus)</span>
                    </label>
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        disabled={isSubmitDisabled}
                        className={`${
                            isSubmitDisabled 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-500 hover:bg-blue-700'
                        } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                    >
                        Enregistrer
                    </button>
                </div>
            </form>
        </div>
    );
}