import { useEffect, useState } from "react"
import { cancelSubscription, countMaxPeopleInSubscription, countRemainingBeneficiairy, deleteBeneficiairy, fetchBeneficiairyUsers, fetchSubscriptionFromUserId } from "../../HTTP/subscription"
import { formatDateExtended, tailwindClassForColoredButton } from "../../Helper/utils"
import { getAdminEmail } from "../../HTTP/admin"
import ListElementsMobile from "../Admin/ListElementsMobile"
import TableElements from "../Admin/TableElements"
import AddBeneficiaryPopup from "./AddBeneficiaryPopup" // Import du nouveau composant

export default function UserSubscription() {
    const user = JSON.parse(localStorage.getItem('user'))
    const [subscription, setSubscription] = useState(null)
    const [searchDoneSubscription, setSearchDoneSubscription] = useState(false)
    const [adminEmail, setAdminEmail] = useState('contact@zemus.info')
    const [beneficiaries, setBeneficiaries] = useState([]) // État pour stocker les bénéficiaires
    const [maxPeople, setMaxPeople] = useState(-1) // État pour stocker le nombre maximum de personnes dans l'abonnement
    const [remainingBeneficiairy, setRemainingBeneficiairy] = useState(-1) // État pour stocker le nombre de bénéficiaires restants
    const [isPopupOpen, setIsPopupOpen] = useState(false) // État pour contrôler l'ouverture de la popup
    const [callAPIDone, setCallAPIDone] = useState(false)
    const [change, setChange] = useState(false)

    useEffect(() => {
        const funGetSubscription = async () => {
            const resSubscription = await fetchSubscriptionFromUserId(user.id)
            setSubscription(resSubscription)
            setSearchDoneSubscription(true)
        }
        const funGetAdminEmail = async () => {
            const resAdminEmail = await getAdminEmail()
            setAdminEmail(resAdminEmail)
        }
        funGetAdminEmail()
        funGetSubscription()
        
    }, [])

    useEffect(() => {
        const fetchMaxPeople = async () => {
            const res = await countMaxPeopleInSubscription(user.id)
            setMaxPeople(res.max)
        }
        fetchMaxPeople()

        const fetchRemainingBeneficiairy = async () => {
            const res = await countRemainingBeneficiairy(user.id)
            setRemainingBeneficiairy(res.remaining)
        }
        fetchRemainingBeneficiairy()

        const fetchBeneficiaries = async () => {
            const res = await fetchBeneficiairyUsers(user.id)
            setBeneficiaries(res)
        }
        fetchBeneficiaries()

        setCallAPIDone(true)
    }, [change])

    // Fonction pour ouvrir/fermer la popup
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen)
    }

    const handleDeleteBeneficiary = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce bénéficiaire?")) {
            try {
                const remove = await deleteBeneficiairy(parseInt(id), user.id)
                if (remove) {
                    setChange(!change)
                }
            } catch (error) {
                console.error("Erreur lors de la suppression du bénéficiaire:", error)
                alert("Une erreur est survenue lors de la suppression du bénéficiaire")
            }
        }
    }

    if (!searchDoneSubscription) {
        return (
            <div className="flex justify-center items-center min-h-[200px]">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
        )
    }

    if (searchDoneSubscription && subscription.data === null) {
        return (
            <div className="max-w-2xl mx-auto p-4 mt-8">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-6">
                        <h2 className="text-2xl font-bold text-center mb-6">
                            Gestion de votre abonnement
                        </h2>
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
                            <div className="flex items-center">
                                <div className="text-red-500">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-red-800 font-medium">Aucun abonnement actif</h3>
                                    <p className="text-red-700 mt-1">
                                        Vous n'avez pas d'abonnement en cours. Souscrivez à un abonnement pour accéder à tous nos services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className="max-w-2xl mx-auto p-4 mt-8">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                    <h2 className="text-2xl font-bold text-center mb-6">
                        Gestion de votre abonnement
                    </h2>
                    
                    <div className="space-y-6">
                        <div className={`p-4 rounded ${
                            subscription.data.status === "active" 
                                ? "bg-green-50 border-l-4 border-green-500" 
                                : subscription.data.status === "canceled"
                                ? "bg-yellow-50 border-l-4 border-yellow-500"
                                : "bg-red-50 border-l-4 border-red-500"
                        }`}>
                            <div className="flex items-center">
                                <div className={`${
                                    subscription.data.status === "active" 
                                        ? "text-green-500" 
                                        : subscription.data.status === "canceled"
                                        ? "text-yellow-500"
                                        : "text-red-500"
                                }`}>
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        {subscription.data.status === "active" ? (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        ) : subscription.data.status === "canceled" ? (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                        ) : (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                        )}
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <h3 className={`font-medium ${
                                        subscription.data.status === "active" 
                                            ? "text-green-800" 
                                            : subscription.data.status === "canceled"
                                            ? "text-yellow-800"
                                            : "text-red-800"
                                    }`}>
                                        {subscription.data.status === "active" 
                                            ? "Abonnement actif" 
                                            : subscription.data.status === "canceled"
                                            ? "Abonnement résilié"
                                            : "Abonnement terminé"}
                                    </h3>
                                    <p className={`mt-1 ${
                                        subscription.data.status === "active" 
                                            ? "text-green-700" 
                                            : subscription.data.status === "canceled"
                                            ? "text-yellow-700"
                                            : "text-red-700"
                                    }`}>
                                        {subscription.data.status === "active" 
                                            ? `Votre prochain paiement est prévu pour le ${formatDateExtended(subscription.data.end_date)}`
                                            : subscription.data.status === "canceled"
                                            ? `Votre abonnement prendra fin le ${formatDateExtended(subscription.data.end_date)}`
                                            : `Votre abonnement s'est arrêté le ${formatDateExtended(subscription.data.end_date)}`}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg">
                            <p className="text-sm text-gray-600">
                                Date de début : {formatDateExtended(subscription.data.start_date)}
                            </p>
                        </div>

                        {subscription.data.status === "active" && (
                            <div className="mt-6 border-t pt-6">
                                <button
                                    onClick={() => window.location.href=`mailto:${adminEmail}?subject=Annulation d\'abonnement&body=Bonjour,%0D%0A%0D%0AJe souhaite procéder à l\'annulation de mon abonnement.%0D%0A%0D%0ANuméro client : ${user.id}%0D%0A%0D%0ACordialement,%0D%0A${user.firstname} ${user.lastname}`}
                                    className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                    <span>Annuler mon abonnement</span>
                                </button>
                                <p className="text-sm text-gray-500 mt-2 text-center">
                                    L'annulation prendra effet à la fin de la période en cours
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        {
            maxPeople > 0 && callAPIDone && (
                <>
                <div className="flex-grow">
                    <h2 className="text-center mt-4 text-2xl font-bold">
                        Gestion bénéficiaires
                    </h2>
                    {
                        remainingBeneficiairy > 0 && (
                            <button
                                className={tailwindClassForColoredButton("green") + " mt-4 mb-4 ml-8"}
                                onClick={togglePopup}
                            >
                                {`Ajouter un bénéficiaire (${remainingBeneficiairy} restants)`}
                            </button>
                        )
                    }
                    {
                        (
                            <>
                            <div className="hidden xl:block ml-8 mr-8">
                                <TableElements 
                                    columns={[
                                        {props:'lastname',display:"Nom"},
                                        {props:'firstname',display:"Prénom"},
                                        {props:'email',display:"Email"},
                                        {props:'',display:"Actions"}
                                    ]}
                                    data={beneficiaries}
                                    buttons={[{display:'Supprimer',action:handleDeleteBeneficiary, color:'red', props:'id'}]}
                                    />
                            </div>
                            <div className="xl:hidden w-full px-4 space-y-4">
                                <ListElementsMobile
                                    columns={[
                                        {props:'lastname',display:"Nom"},
                                        {props:'firstname',display:"Prénom"},
                                        {props:'email',display:"Email"},
                                        {props:'',display:"Actions"}
                                    ]}
                                    data={beneficiaries}
                                    buttons={[{display:'Supprimer',action:handleDeleteBeneficiary, color:'red', props:'id'}]}
                                />
                            </div>
                            </>
                        )
                    }
                </div>

                <AddBeneficiaryPopup 
                    isOpen={isPopupOpen} 
                    onClose={togglePopup}
                    change={change}
                    onChange={setChange}
                />
                </>
            )
        }
        </>
    )
}