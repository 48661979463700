import React, { useEffect, useState } from "react";
import { countries } from "../../global/countriesAndSources";
import etoille from "../../media/star.png"
import "./GoogleCSE.css"
import { getCountriesFromCountryList, getIgo } from "../../HTTP/translation";
import { use } from "react";

export const CountryBar = (props) => {
    const countrySelected = props.selectedCountry;
    const funChangeCountry = props.setSelectedCountry;
    const funChangeDisplayMap = props.displayMap;
    const funSearchThematics = props.searchThematics;
    const selectedIgo2 = props.selectedIgo;
    const funChangeIgo2 = props.setSelectedIgo;
    const isMobile = props.isMobile;
    const themes = props.thematics;
    const igo = props.igo;
    const igoReady = props.igoReady;
    const [countriesFromAPI, setCountriesFromAPI] = useState([])
    const [countryReady, setCountryReady] = useState(false)
    const url = new URL(window.location.href)
    const isUserSubscribed = JSON.parse(localStorage.getItem("subscriptions")) !== null

    useEffect(() => {
        const funCountries = async () => {
            const res = await getCountriesFromCountryList(selectedIgo2.country_list)
            setCountriesFromAPI(res)
        }
        if (igoReady) {
            funCountries()
        }
    }, [selectedIgo2, igoReady])

    const getARandomCountry = () => {
        const country = countries[Math.floor(Math.random() * countries.length)]
		//Array.from(document.getElementsByClassName('divli')).find((c) => c.innerText === country.name).scrollIntoView({ behavior: 'smooth'})
		funChangeCountry(country)
	}

    const handleChangeIgo = (e) => {
        const igoSelected = igo.find((i) => i.name === e.target.value)
        funChangeIgo2(igoSelected)
        if (igoSelected.name !== "Selectionner une OIG") {
            url.searchParams.set('igo', igoSelected.name)
            window.history.pushState({}, '', url)
            sessionStorage.setItem('search_igo', igoSelected.name)
        } else {
            url.searchParams.delete('igo')
            window.history.pushState({}, '', url)
            sessionStorage.removeItem('search_igo')
        }
    }

    return (
        <>
            {
                isUserSubscribed && (
                    <input type="text" id={isMobile ? "searchCountryMobile" : "searchCountry"} placeholder="Rechercher un pays..." className="ml-4 mt-4 mr-4 mb-2 w-4/5 border-2 border-cyan-600" onKeyUp={() => {
                        var input, filter, div, a, i;
                        input = isMobile ? document.getElementById("searchCountryMobile") : document.getElementById("searchCountry");
                        filter = input.value.toUpperCase();
                        div = isMobile ? document.getElementById("country_box_mobile") : document.getElementById("country_box");
                        a = isMobile ? div.getElementsByClassName("country_selection_mobile") : div.getElementsByClassName("country_selection");
                    
                        for (i = 0; i < a.length; i++) {
                            let txtValue = a[i].textContent || a[i].innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                                a[i].style.display = "";
                            } else {
                                a[i].style.display = "none";
                            }
                        }
                    }}>
                    </input>
                )
            }
            {
                igoReady && isUserSubscribed && (
                    <select id="igo" className="mr-4 ml-4 mb-4 mt-2 w-4/5 border-2 border-cyan-600" value={selectedIgo2.name} onChange={handleChangeIgo}>
                        {
                            igo.map((item, index) => 
                                <option key={index} value={item.name}>{item.name}</option>
                            )
                        }
                    </select>
                )
            }
            <div className="ulflag">
                <div id={isMobile ? "country_box_mobile" : "country_box"} className={isUserSubscribed === false ? "mt-4" : "mt-0"}>
                    {
                        selectedIgo2.name === "Séléctionner une OIG" && (
                            <>
                            {
                                isUserSubscribed && (
                                    <ul className="divli" onClick={() => { funChangeDisplayMap() } } key="mapdisplay"> 
                                        <li className={isMobile ? "country_selection_mobile" : "country_selection"} key="mapdisplaychild"> 
                                            <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                            <a href="#">Afficher la carte</a>
                                        </li>
                                    </ul>
                                )
                            }
                            <ul className="divli" onClick={() => { getARandomCountry() } } key="random"> 
                                <li className={isMobile ? "country_selection_mobile" : "country_selection"} key="randomchild"> 
                                    <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                    <a href="#">Aléatoire</a>
                                </li>
                            </ul>
                            </>
                        )
                    }
                    {
                        countriesFromAPI.map((c, i) => 
                        <React.Fragment key={`country-${c.code}`}>
                           
                            <ul className="divli" onClick={() => { funChangeCountry(c) } } style={{ backgroundColor: c.code === countrySelected.code ? '#b4f6ff' : "transparent"}} > 
                                {
                                    isUserSubscribed && (
                                        <li className={isMobile ? "country_selection_mobile" : "country_selection"} > 
                                            <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                            <img src={process.env.PUBLIC_URL + '/flags/' + c.code + ".svg"} alt={c.name} /><a href="#">{c.name}</a>
                                        </li>
                                    )
                                }
                                {
                                    isUserSubscribed === false && JSON.parse(localStorage.getItem("user")).country === c.code && (
                                        <li className={isMobile ? "country_selection_mobile" : "country_selection"} > 
                                            <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                            <img src={process.env.PUBLIC_URL + '/flags/' + c.code + ".svg"} alt={c.name} /><a href="#">{c.name}</a>
                                        </li>
                                    )
                                }
                            </ul>
                            <div id="themes_country">
                            { 
                                themes && c.code === countrySelected.code && (
                                    themes.map((item, index) => 
                                        <button 
                                            id="theme_country"
                                            key={index} 
                                            onClick={() => funSearchThematics(item.theme)}
                                            >
                                            {item.theme}
                                        </button>
                                    )
                                )
                            }
                            </div>
                        </React.Fragment>
                        )
                    }
                </div>
            </div>
        </>
    )
}