import React, { useEffect, useState } from 'react';
import { languages } from '../../global/countriesAndSources';
import { getLangFromCountry } from '../../HTTP/translation';
import { type } from '@testing-library/user-event/dist/type';

function Translate() {
  const [currentLang, setCurrentLang] = useState('fr');
  const [langUser, setLangUser] = useState('');
  const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);

  useEffect(() => {
    const funGetLangUser = async () => {
      const result = await getLangFromCountry(JSON.parse(localStorage.getItem("user")).country);
      setLangUser(result);
    }
    funGetLangUser();
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'fr',
        autoDisplay: false
      },
      "google_translate_element"
    );
    setIsWidgetInitialized(true);
  };

  const updateUrlLang = (lang) => {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    window.history.pushState({}, '', url);
  };

  // Effet pour initialiser le widget
  useEffect(() => {
    const existingScript = document.getElementById('google_translate_script');
    if (!existingScript) {
      var addScript = document.createElement("script");
      addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
      addScript.setAttribute("id", "google_translate_script");
      document.body.appendChild(addScript);
    }

    window.googleTranslateElementInit = googleTranslateElementInit;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
          const newLang = document.documentElement.lang;
          setCurrentLang(newLang);
          sessionStorage.setItem('currentlanguage',newLang);
          updateUrlLang(newLang);
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
      window.googleTranslateElementInit = undefined;
      observer.disconnect();
    };
  }, []);

  // Effet pour changer la langue initiale si différente de 'fr'
  useEffect(() => {
    if (isWidgetInitialized && langUser !== 'fr' && sessionStorage.getItem('initLang') !== 'true') {
      // On attend un peu que le widget soit complètement chargé
      const timer = setTimeout(() => {
        const googleTranslateSelect = document.querySelector('.goog-te-combo');
        if (googleTranslateSelect) {
          googleTranslateSelect.value = langUser;
          googleTranslateSelect.dispatchEvent(new Event('change'));
        }
        sessionStorage.setItem('initLang', 'true');
      }, 1000); // Délai d'une seconde pour s'assurer que le widget est chargé

      return () => clearTimeout(timer);
    }
  }, [isWidgetInitialized, langUser]);

  const handleLanguageChange = (event) => {
    const newLang = event.target.value;
    console.log(newLang);
    setCurrentLang(newLang);
    sessionStorage.setItem('currentlanguage',newLang);
    updateUrlLang(newLang);
    const googleTranslateSelect = document.querySelector('.goog-te-combo');
    if (googleTranslateSelect) {
      googleTranslateSelect.value = newLang;
      googleTranslateSelect.dispatchEvent(new Event('change'));
      if (newLang === 'fr') {
        closeGoogleTranslateToolbar();
      }
    } else {
      console.log('NOPE');
    }
  };

  const closeGoogleTranslateToolbar = () => {
    const googleTranslateSelect = document.querySelector('.goog-te-combo');
    if (googleTranslateSelect) {
      googleTranslateSelect.value = 'fr';
      googleTranslateSelect.dispatchEvent(new Event('change'));
    }
  };

  return (
    <>
      <div id="google_translate_element" style={{ display: 'none' }}></div>
      <select 
        className={`bg-[#009AAD] text-white p-1 rounded static float-right`} 
        value={currentLang} 
        onChange={handleLanguageChange}
      >
        {languages.map((item, index) => (
          <option 
            style={{ color: "black" }}
            className='actuins' 
            key={index} 
            value={item.language_code}
          >
            {item.language_name}
          </option>
        ))}
      </select>
    </>
  );
}

export default Translate;