import React from "react";
import { useState } from "react";
import Logo from "../../media/logonobg.png";
import "./Login.css";
import bienvenue from "../../media/bienvenue.jfif";
import register from "../../media/enregis.jpeg";
import AuthService from "../../HTTP/auth.service";

const ResetPassword = () => {
    // État pour suivre l'étape actuelle du processus
    const [step, setStep] = useState(1); // 1: Email, 2: Code, 3: Nouveau mot de passe
    
    // États pour les entrées utilisateur
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    // États pour les messages d'erreur/succès
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    
    // Fonction pour gérer la soumission d'email (étape 1)
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMsg("");
        setSuccessMessage("");
        
        // Validation simple côté client
        if (!email || !email.includes("@")) {
            setErrorMsg("Veuillez entrer une adresse email valide.");
            setLoading(false);
            return;
        }
        
        try {
            // Appel à l'API pour obtenir le code de réinitialisation
            const response = await AuthService.getCodeForResetPassword(email);
            setSuccessMessage("Un code de vérification a été envoyé à votre adresse email.");
            setStep(2);
        } catch (err) {
            setErrorMsg(err.message || "Une erreur s'est produite lors de l'envoi du code.");
        } finally {
            setLoading(false);
        }
    };
    
    // Fonction pour gérer la vérification du code (étape 2)
    const handleCodeVerification = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMsg("");
        setSuccessMessage("");
        
        // Validation simple côté client
        if (!verificationCode || verificationCode.length < 4) {
            setErrorMsg("Veuillez entrer un code de vérification valide.");
            setLoading(false);
            return;
        }
        
        try {
            // Appel API pour vérifier le code
            const response = await AuthService.verifyResetCode(email, verificationCode);
            setSuccessMessage("Code vérifié avec succès.");
            setStep(3);
        } catch (err) {
            setErrorMsg(err.message || "Erreur lors de la vérification du code.");
        } finally {
            setLoading(false);
        }
    };
    
    // Fonction pour gérer le changement de mot de passe (étape 3)
    const handlePasswordReset = async (e) => {
        console.log("handlePasswordReset");
        e.preventDefault();
        setLoading(true);
        setErrorMsg("");
        setSuccessMessage("");
        
        // Validation locale du mot de passe
        if (newPassword.length < 6) {
            setErrorMsg("Le mot de passe doit contenir au moins 6 caractères.");
            setLoading(false);
            return;
        }
        
        if (newPassword !== confirmPassword) {
            setErrorMsg("Les mots de passe ne correspondent pas.");
            setLoading(false);
            return;
        }
        
        try {
            // Appel API pour réinitialiser le mot de passe
            const response = await AuthService.resetPassword(email, verificationCode, newPassword);
            
            setSuccessMessage("Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter.");
            // Redirection vers la page de connexion après un délai
            setTimeout(() => {
                window.location.href = "/login";
            }, 3000);
        } catch (err) {
            setErrorMsg(err.message || "Erreur lors de la réinitialisation du mot de passe.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    
    // Fonction pour revenir à l'étape précédente
    const handleBack = () => {
        setErrorMsg("");
        setSuccessMessage("");
        setStep(step - 1);
    };

    // Rendu du contenu en fonction de l'étape actuelle
    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <form onSubmit={handleEmailSubmit}>
                        <img
                            src={Logo}
                            className="logo"
                            style={{ width: "220px", marginTop: "0", maxWidth: "80%", marginBottom: "15px" }}
                            alt="Logo"
                        />
                        <h1 className="titr">Réinitialiser le mot de passe</h1>
                        <p>Veuillez entrer votre adresse email pour recevoir un code de vérification.</p>
                        <input 
                            type="email" 
                            placeholder="Email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required 
                            />
                        <button type="submit" style={{ background: "#009AAD", borderColor: "#009AAD" }} disabled={loading}>
                            {loading ? "Envoi en cours..." : "Envoyer le code"}
                        </button>
                            { 
                                errorMsg && <div className="divErrMessage"> <p style={{marginTop: 25, fontWeight: 'bold'}}>{errorMsg}</p> </div> 
                            } 
                            { 
                                successMessage && <div className="divSuccessMessage"> <p style= {{color: "rgb(41, 175, 79)", fontSize: 19, marginTop: 25, fontWeight: 'bold'}}>{successMessage}</p> </div> 
                            }
                    </form>
                );
            case 2:
                return (
                    <form onSubmit={handleCodeVerification}>
                        <img
                            src={Logo}
                            className="logo"
                            style={{ width: "220px", marginTop: "0", maxWidth: "80%", marginBottom: "15px" }}
                            alt="Logo"
                        />
                        { 
                            errorMsg && <div className="divErrMessage"> <p style={{marginTop: 25, fontWeight: 'bold'}}>{errorMsg}</p> </div> 
                        } 
                        { 
                            successMessage && <div className="divSuccessMessage"> <p style= {{color: "rgb(41, 175, 79)", fontSize: 19, marginTop: 25, fontWeight: 'bold'}}>{successMessage}</p> </div> 
                        }
                        <h1 className="titr">Vérification</h1>
                        <p>Entrez le code à 6 chiffres envoyé à {email}</p>
                        <input 
                            type="text" 
                            placeholder="Code de vérification" 
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            maxLength={6}
                            required 
                        />
                        <div style={{ display: "flex", width: "68%", justifyContent: "space-between", marginTop: "10px" }}>
                            <button type="button" onClick={handleBack} style={{ marginTop: "8px", color: "black" }} disabled={loading}>
                                Retour
                            </button>
                            <button type="submit" style={{ background: "#009AAD", borderColor: "#009AAD" }} disabled={loading}>
                                {loading ? "Vérification..." : "Vérifier"}
                            </button>
                        </div>
                    </form>
                );
            case 3:
                return (
                    <form onSubmit={handlePasswordReset}>
                        <img
                            src={Logo}
                            className="logo"
                            style={{ width: "220px", marginTop: "0", maxWidth: "80%", marginBottom: "15px" }}
                            alt="Logo"
                        />
                        <h1 className="titr">Nouveau mot de passe</h1>
                        <p>Créez un nouveau mot de passe pour votre compte.</p>
                        <input
                            type="hidden"
                            value={email}
                            />
                        <input 
                            type="password" 
                            placeholder="Nouveau mot de passe" 
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required 
                            />
                        <input 
                            type="password" 
                            placeholder="Confirmer le mot de passe" 
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required 
                            />
                        <div style={{ display: "flex", width: "68%", justifyContent: "space-between", marginTop: "10px" }}>
                            <button type="button" onClick={handleBack} style={{ marginTop: "8px", color: "black" }} disabled={loading}>
                                Retour
                            </button>
                            <button type="submit" style={{ background: "#009AAD", borderColor: "#009AAD" }} disabled={loading}>
                                {loading ? "Réinitialisation..." : "Réinitialiser"}
                            </button>
                        </div>
                        { 
                            errorMsg && <div className="divErrMessage"> <p style={{marginTop: 25, fontWeight: 'bold'}}>{errorMsg}</p> </div> 
                        } 
                        { 
                            successMessage && <div className="divSuccessMessage"> <p style= {{color: "rgb(41, 175, 79)", fontSize: 19, marginTop: 25, fontWeight: 'bold'}}>{successMessage}</p> </div> 
                        }
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <div className="Bodylogin">
            <div className={`containerLogin right-panel-active`} id="container">
                <div className="form-container sign-up-container">
                    {renderStepContent()}
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div
                        style={ {backgroundImage: `url(${bienvenue})` } }
                        className="overlay-panel overlay-left"
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;