import React, { useState, useEffect, useContext } from "react";
import "./Profile.css"
import verificato from "../../media/verificato.png";
import { Context } from '../../Helper/Context';
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import Menu  from "@mui/material/Menu";
//import { userInfo } from "./userStructure"
import SwitchList from '../Switch/Switch'; // Assurez-vous que le chemin est correct 
import userApi from "../../HTTP/user"
import ReviewBlocks from '../Review/ReviewBlocks'; // Assurez-vous que le chemin d'importation est correct
import { deleteReview } from "../../HTTP/articles"
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import AuthService from "../../HTTP/auth.service";

const user = JSON.parse(localStorage.getItem("user")) 

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color: "#00D5F3", // Change text color to #00D5F3
		backgroundColor: "transparent", // Change background color to transparent
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				// color: theme.palette.text.secondary,
				color: "#00D5F3", // Change text color to #00D5F3
				backgroundColor: "transparent", // Change background color to transparent
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));
const Profile = () => {
	 
	const [reviews, setReviews] = useState([]); 
	useEffect(() => {
		const fetchReviews = async () => {
		  const reviewsData = await userApi.getUserReviews(user.id); // Assurez-vous que ceci renvoie un tableau 
		  setReviews(reviewsData.reviews);
		};
	  
		fetchReviews();
	  }, []);
	 
	  
	const handleDeleteReview = async (reviewId) => { 
		deleteReview(reviewId).then((res) => {
		  if(res === false) {
			alert('Error while deleting review')
			return
		  } 
		  alert('Review deleted !')
		  setReviews(currentReviews => currentReviews.filter(review => review.id !== reviewId));
		}) 
	};
 
	const {SetFil } = useContext(Context) 

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl); 

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
 

	const handleFileUpload = async (event) => {
        const file = event.target.files[0]; // Obtenez le fichier sélectionné
        if (!file) {
            console.log("Aucun fichier sélectionné.");
            return;
        }
		try {
		  const result = await userApi.setProfilePic(user.id, file); 
		  user.profile_picture = result;  
		  localStorage.setItem('user', JSON.stringify(user));
		  document.getElementById('profile-pic').src = result;
		} catch (error) {
		  console.error('Erreur lors de l\'envoi du fichier:', error);
		}
	  };
	
	const [profileHover, setProfileHover] = useState(false)

	const isUserSubscribed = JSON.parse(localStorage.getItem("subscriptions")) !== null

	return (
		<div className='profileContainer mt-5'>
			<SwitchList />
			<div className="profimg">
				<img id="profile-pic" onMouseEnter={() => setProfileHover(true)} onMouseLeave={() => setProfileHover(false)} src={user.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" } alt="profile" />
				{profileHover &&
					<div onMouseEnter={() => setProfileHover(true)}
						onMouseLeave={() => setProfileHover(false)}
						style={{
							margin: 0,
							alignItems: "center",
							display: "flex",
							marginLeft: "-59px",
							position: "relative",
							left: "-15px",
							width: "59px"
						}}
						className="file-input"
					>
						<input
							type="file"
							name="file-input"
							id="file-input"
							className="file-input__input"
							onChange={handleFileUpload}	
						/>
						<label className="file-input__label" htmlFor="file-input">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="upload"
								className="svg-inline--fa fa-upload fa-w-16"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
							</svg>
						</label>
					</div>
				}


				<div className="mb-2" >
					<div className="profnameprof">
						<div onClick={() => { SetFil("ProfilInfo") }} style={{ display: "flex" }}>
							<h2 className="notranslate"> 
								{ `${user?.firstname} ${user?.lastname}` }
							</h2>
							<img className="verificato" src={verificato} alt="verification" />
						</div>

						{/* <span style={{ marginLeft: "3px" }} className="infofriendspan">A propos</span> */}


						<div className="list1">
							<Button
								id="demo-customized-button"
								aria-controls={open ? "demo-customized-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								variant="contained"
								disableElevation
								onClick={handleClick}
								endIcon={<KeyboardArrowDownIcon />}
								style={{
									backgroundColor: "transparent", color: "#02b3e3", fontSize: "15px", fontWeight: 700, position: "relative"
								}}
							>
								A propos

							</Button>

							<StyledMenu
								id="demo-customized-menu"
								MenuListProps={{
									"aria-labelledby": "demo-customized-button",
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
							>

								<ul className="ulflag">
									<li><button style={{fontSize: 22, paddingTop: 6}} onClick={async () => { localStorage.removeItem("user"); localStorage.removeItem("token"); localStorage.removeItem("subscriptions"); sessionStorage.setItem("initLang", 'false'); await AuthService.disconnect(user.id); window.location.reload() }}> Déconnexion </button></li>
									 <li><a href="/edit">Editer le profil</a></li>
									 <li><a href="/profile/subscription">Voir son abonnement</a></li>
								</ul>

								{/* <Divider sx={{ my: 0.5 }} /> */}
							</StyledMenu>
						</div>


					</div>
					{
						!isUserSubscribed && <button onClick={() => window.location.href = "/Subscription"} className="btnpremm">Passer a la version Premium</button>
					}
				</div>
				
			</div>
			{
				isUserSubscribed &&  (
					<>
					<h1 className="ml-4">Revues de presse</h1> 
					<ReviewBlocks reviews={reviews}  onDeleteReview={handleDeleteReview} edition="true" displayAuthor={false}/>
					</>
				)
			}
		</div>
	)
}

export default Profile
