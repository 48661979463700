import { useState, useParams, useEffect } from "react";
import { createArticle, uploadFeaturedImage } from "../../HTTP/articles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { languages } from "../../global/countriesAndSources";
import { getAllLanguagesFromCountry } from "../../HTTP/translation";

export default function MyArticlesCreate() {
    if (localStorage.getItem("subscriptions") === null) {
        window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }
    const user = JSON.parse(localStorage.getItem("user"))
    const [languagesOfUser, setLanguagesOfUser] = useState([])
    const [listLang, setListLang] = useState([])
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        language: '',
        image: null,
        imagePreview: '',
        visible: 0,
        copyright: false
    });
    
    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        
        if (type === 'file') {
            const file = files[0];
            if (file) {
                if (!file.type.startsWith('image/')) {
                    alert('Veuillez sélectionner une image valide');
                    return;
                }
                
                if (file.size > 5 * 1024 * 1024) {
                    alert('L\'image ne doit pas dépasser 5MB');
                    return;
                }
                
                const previewUrl = URL.createObjectURL(file);
                
                setFormData(prevData => ({
                    ...prevData,
                    image: file,
                    imagePreview: previewUrl,
                    copyright: false // Réinitialise le copyright quand une nouvelle image est chargée
                }));
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const handleRemoveImage = () => {
        if (formData.imagePreview) {
            URL.revokeObjectURL(formData.imagePreview);
        }
        
        setFormData(prevData => ({
            ...prevData,
            image: null,
            imagePreview: '',
            copyright: false // Réinitialise le copyright quand l'image est supprimée
        }));
        
        const fileInput = document.getElementById('image');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    useEffect(() => {
        return () => {
            if (formData.imagePreview) {
                URL.revokeObjectURL(formData.imagePreview);
            }
        };
    }, [formData.imagePreview]);
    
    const handleSubimeChange = (content) => {
        setFormData(prevData => ({
            ...prevData,
            description: content
        }));
    }
    
    useEffect(() => {
        const funGetLanguages = async () => {
            const langFromUser = await getAllLanguagesFromCountry(user.country)
            setLanguagesOfUser(langFromUser)
        }
        funGetLanguages()
    }, [])
    
    useEffect(() => {
        const langList = languages.filter(lang => languagesOfUser.includes(lang.language_code))
        setListLang(langList)
        setFormData(prevData => ({
            ...prevData,
            language: languagesOfUser[0]
        }))
    }, [languagesOfUser])

    // Vérifie si le formulaire peut être soumis
    const isSubmitDisabled = formData.image && !formData.copyright;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await createArticle(user.id, formData.title, formData.description, formData.language === '' ? languagesOfUser[0] : formData.language, formData.visible)
            console.log(res)
            if (formData.image) {
                const res2 = await uploadFeaturedImage(res.articleId, formData.image)
                alert('Article added successfully with image')
            } else {
                alert('Article added successfully without image')
            }
            window.location.href = '/myarticles'
        } catch (err) {
            alert("An error occured while saving the article");
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Article</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
                        Titre
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="language" className="block text-gray-700 text-sm font-bold mb-2">
                        Langue de l'article
                    </label>
                    <select 
                        value={formData.language} 
                        onChange={handleChange} 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                        name="language"
                    >
                        {
                            languages.map((l, i) => <option value={l.language_code} key={i}>{l.language_name}</option>)
                        }
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="image" className="block text-gray-700 text-sm font-bold mb-2">
                        Image à la une
                    </label>
                    <div className="flex gap-2">
                        <input
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {formData.image && (
                            <button
                                type="button"
                                onClick={handleRemoveImage}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Supprimer
                            </button>
                        )}
                    </div>
                    {formData.imagePreview && (
                        <div className="mt-2">
                            <img
                                src={formData.imagePreview}
                                alt="Preview"
                                className="max-w-full h-auto rounded-md"
                                />
                        </div>
                    )}
                    {formData.image && (
                        <div className="mb-6">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="copyright"
                                    checked={formData.copyright}
                                    onChange={handleChange}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700">Je suis l'auteur de l'image à la une ou j'ai les droits d'utilisation</span>
                            </label>
                        </div>
                    )}
                </div>
                <div className="mb-4">
                    <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
                        Description
                    </label>
                    <ReactQuill
                        theme="snow"
                        value={formData.description}
                        onChange={handleSubimeChange}
                        className="notranslate"
                    />
                </div>
                <div className="mb-6">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="visible"
                            checked={formData.visible}
                            onChange={handleChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">Rendre public l'article (sera modéré par les équipes de Zemus)</span>
                    </label>
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        disabled={isSubmitDisabled}
                        className={`${
                            isSubmitDisabled 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-500 hover:bg-blue-700'
                        } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                    >
                        Enregistrer
                    </button>
                </div>
            </form>
        </div>
    );
}