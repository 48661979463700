import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { Context } from "../../Helper/Context";
import etoille from "../../media/star.png"
import "./Home.css";
import "./GoogleCSE.css"
import { countries, sourceTypes, listMiddle } from "../../global/countriesAndSources"
import Translate from '../Translate/Translate';
import Map from "../Map/Map";
import { CountryBar } from "./countryBar";
import SaveIcon from '@mui/icons-material/Save';
import { Save } from "@mui/icons-material";
import { addKeyword } from "../../HTTP/keywords";

const user = JSON.parse(localStorage.getItem("user"))
if (sessionStorage.getItem('listmiddle') === null) {
	sessionStorage.setItem('listmiddle', "De quoi parle-t-on ?")
}

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const StyledMenu1 = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 350,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));


export const ProfileHeader = (props) => {
	
	const vw = window.innerWidth / 100

	const [isHover, setisHover] = useState(false)

	const setSelectedCountry = props.setSelectedCountry
	const selectedCountry = props.selectedCountry
	const selectedTheme = props.selectedTheme
	const themes = props.thematics;
	const funSearchThematics = props.searchThematics;
	const funChangeDisplayMap = props.displayMap;
	const igo = props.igo;
    const igoReady = props.igoReady;
	const selectedIgo2 = props.selectedIgo;
    const funChangeIgo2 = props.setSelectedIgo;
	const [selectedSource, setSelectedSource] = useState({name: "Toutes les sources", code_name: "all"})
	const [selectedListMiddle, setSelectedListMiddle] = useState(sessionStorage.getItem('listmiddle'))

	const [anchorSources, setAnchorSources] = useState()
	const [anchorListMiddle, setAnchorListMiddle] = useState()
	const [anchorCountries, setAnchorCountries] = useState()

	const openSources = (event) => setAnchorSources(event.currentTarget)
	const openListMiddle = (event) => setAnchorListMiddle(event.currentTarget)
	const openCountries = (event) => setAnchorCountries(event.currentTarget)
	
	const closeSources = () => setAnchorSources(null)
	const closeListMiddle = () => setAnchorListMiddle(null)
	const closeCountries = () => setAnchorCountries(null)

	const listMiddleRedirection = (item) => {
		if (item === "De quels pays parle-t-on ?") {
			sessionStorage.setItem("search_query", "")
			window.location = `/${selectedCountry.code}/the-country-speaks-of`
		}	
		else if (item === "Quels pays en parlent ?") { 
			sessionStorage.setItem("search_query", "")
			window.location = `/${selectedCountry.code}/which-countries-speak-about`
		}
		else window.location.href = `/${selectedCountry.code}/what-this-country-talk-about`
	}

	const getARandomCountry = () => {
		const country = countries[Math.floor(Math.random() * countries.length)]
		Array.from(document.getElementsByClassName('divli')).find((c) => c.innerText === country.name).scrollIntoView({ behavior: 'smooth', block: 'center' })
		setSelectedCountry(country)
		closeCountries()
	}

	const profileHeaderDisplayMap = () => {
		funChangeDisplayMap()
		closeCountries()
	}

	const handleAddKeyword = async () => {
		const url = new URL(window.location.href)
		const thematic = url.searchParams.get('thematic')
		const keyword = {
			user_id: user.id,
			keyword: sessionStorage.getItem("search_query"),
			country_code: selectedCountry.code,
			thematic: thematic === "" || thematic === null ? "" : thematic
		}
		try {
			await addKeyword(keyword)
			alert("Keyword saved")
		} catch (error) {
			alert("Problem saving keyword")
		}
	}

	const isUserSubscribed = JSON.parse(localStorage.getItem("subscriptions")) !== null

	return (
		<div style={{display: "flex", flexDirection: "column", flexWrap: 'wrap'}} className="mt-8 mb-4">
			<div className="profile">
				<div className="icon">
					<Stack direction="row" spacing={2} id="stack_thing_in_header">
						<img 
							onMouseEnter={() => setisHover(true)} 
							onMouseLeave={() => setisHover(false)} 
							className="iconProfile" 
							src={user.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" }
							alt="profile"
						 />
						
						<div className="profilename">
							<h2 className="notranslate">
								{ `${user?.firstname} ${user?.lastname}`} 
							</h2>
							{
								!isUserSubscribed && (
									<>
									<button
										className="btnpreprofile"
										onClick={() => window.location.href = "/Subscription"}
									>
										Passer a la version Premium
									</button>
									</>
								)
							}
						</div>
					</Stack>
				</div>
				<div className="name"></div>

				<div className="search">
					<div className="text-left mb-1">
						{selectedTheme}
					</div>
					<div className="gcse-searchbox" />
					<div className="translate-and-save flex flex-row justify-between">

						<div className={`flex items-center space-x-2 ${isUserSubscribed ? "" : "hidden"}`}>
							<input type="checkbox" id="translate_query" />
							<label htmlFor="translate_query">Traduction automatique</label>
							
						</div>
						{
							isUserSubscribed && (
								<button
									type="button"
									className="p-2 bg-savesearch hover:bg-savesearch text-black rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
									onClick={() => handleAddKeyword()}
									>
										Sauvegarder la recherche
									
								</button>
							)
						}
					</div>
				</div>

			</div>

			{ window.location.href[3] !== "friends-msg" && 
			<div className="droplist">

				<div className="list3 ">
					<Button
						style={{ backgroundColor: "#009AAD", paddingHorizontal: 120}}
						className="search_menu_selection_country"
						id="demo-customized-button"
						aria-controls={ Boolean(anchorCountries) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorCountries) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={ openCountries }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedCountry.name }

					</Button>
					<StyledMenu
						id="demo-customized-menu"
						className="h-96"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						anchorEl={ anchorCountries }
						open={ Boolean(anchorCountries) }
						onClose={ closeCountries }
					>
						<CountryBar
							selectedCountry = {selectedCountry}
							setSelectedCountry = {setSelectedCountry}
							displayMap = {profileHeaderDisplayMap}
							isMobile = {true}
							igo = {igo}
							igoReady = {igoReady}
							selectedIgo = {selectedIgo2}
							setSelectedIgo = {funChangeIgo2}
							/>

					{/* <Divider sx={{ my: 0.5 }} /> */}
					</StyledMenu>
				</div>
			</div>
			

		} 
	</div>

	)
}
