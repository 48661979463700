import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import smile from "../../media/smile.png";
import { addFactChecking, getArticleContentFromScreenshot, getFactChecking } from "../../HTTP/articles";

export const FactChecking = ({ articleLink, setShowPopUpFactChecking }) => {
  const [factChecking, setFactChecking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [articleContent, setArticleContent] = useState("");
  const language = sessionStorage.getItem("currentlanguage");

  // Fonction pour chercher un fact-checking existant
  useEffect(() => {
    const fetchFactChecking = async () => {
      try {
        setLoading(true);
        const res = await getFactChecking(articleLink, language);
        setFactChecking(res);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching fact checking:", error);
        setLoading(false);
      }
    };
    
    fetchFactChecking();
  }, [articleLink, language]);

  // Cette fonction simulera la récupération du contenu de l'article
  // Vous devrez l'implémenter selon votre API
  const getArticleContent = async () => {
    setLoading(true);
    try {
      console.log(language, articleLink);
      // Ici vous implémenterez votre appel API
      const content = await getArticleContentFromScreenshot(articleLink);
      setArticleContent(content);
      console.log("Contenu de l'article récupéré:", content);
      setLoading(false);
      
      // Simulation pour cet exemple
      // setTimeout(() => {
      //   setArticleContent("Contenu de l'article à fact-checker...");
      //   setLoading(false);
      // }, 1000);
    } catch (error) {
      console.error("Erreur lors de la récupération du contenu de l'article:", error);
      setLoading(false);
    }
  };

  // Cette fonction simulera la génération d'un nouveau fact-checking
  // Vous devrez l'implémenter selon votre API
  const generateFactChecking = async () => {
    setIsGenerating(true);
    try {
      // Ici vous implémenterez votre appel API
      const newFactCheck = await addFactChecking(articleLink, articleContent);
      setFactChecking(newFactCheck);
      console.log("Fact-checking généré:", newFactCheck);
      setIsGenerating(false);
      
      // Simulation pour cet exemple
      // setTimeout(() => {
      //   setFactChecking({
      //     content: "Nouveau fact-checking généré pour cet article.",
      //     author: "IA Factchecker",
      //     date: new Date().toLocaleDateString()
      //   });
      //   setIsGenerating(false);
      // }, 1500);
    } catch (error) {
      console.error("Erreur lors de la génération du fact-checking:", error);
      setIsGenerating(false);
    }
  };

  // Récupérer le contenu de l'article si nécessaire
  useEffect(() => {
    if (!factChecking && !loading && articleContent === "") {
      getArticleContent();
    }
  }, [factChecking, loading, articleContent]);

  // Afficher un loader pendant le chargement initial
  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-800 text-white rounded-lg shadow-md overflow-hidden w-96 p-4">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
          <p className="text-center mt-4">Chargement en cours...</p>
        </div>
      </div>
    );
  }

  // Afficher le fact-checking s'il existe
  if (factChecking) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-800 text-white rounded-lg shadow-md overflow-hidden w-96">
          <div className="flex flex-col">
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">Vérification des faits</h3>
              <div className="bg-gray-700 p-3 rounded mb-4 max-h-60 overflow-y-auto">
                <ReactMarkdown>
                  {factChecking.content}
                </ReactMarkdown>
              </div>
              <div className="flex items-center mb-4">
                <img
                  className="w-8 h-8 rounded-full mr-2"
                  src={smile}
                  alt="smile"
                />
                <p className="text-sm">{factChecking.author || "Zemus"}</p>
                <p className="text-sm ml-2">{factChecking.date || "Aujourd'hui"}</p>
              </div>
              <button
                onClick={() => setShowPopUpFactChecking(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Afficher l'interface pour générer un nouveau fact-checking
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 text-white rounded-lg shadow-md overflow-hidden w-96">
        <div className="flex flex-col">
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Générer une vérification des faits</h3>
            <p className="text-gray-300 mb-4">
              Aucune vérification n'existe pour cet article. Vous pouvez en générer une nouvelle.
            </p>

            <p>
              <a href={articleLink} target="_blank">Lien vers l'article</a>
            </p>
            
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Contenu de l'article</label>
              <textarea 
                className="w-full h-32 p-2 text-gray-900 rounded" 
                value={articleContent}
                onChange={(e) => setArticleContent(e.target.value)}
                placeholder="Le contenu de l'article à vérifier apparaîtra ici..."
              ></textarea>
            </div>
            
            <div className="flex gap-2">
              {isGenerating ? (
                <button
                  disabled
                  className="bg-blue-500 opacity-70 text-white font-bold py-2 px-4 rounded flex-1 flex items-center justify-center"
                >
                  <span className="animate-spin h-4 w-4 border-t-2 border-b-2 border-white rounded-full mr-2"></span>
                  Génération...
                </button>
              ) : (
                <button
                  onClick={generateFactChecking}
                  disabled={!articleContent}
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-1 ${!articleContent ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Générer un fact-check
                </button>
              )}
              <button
                onClick={() => setShowPopUpFactChecking(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex-1"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};