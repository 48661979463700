import React, { useState, useEffect} from "react";
import "./Profile.css"
import verificato from "../../media/badge.png";
import userApi from "../../HTTP/user"
import ReviewBlocks from '../Review/ReviewBlocks'; // Assurez-vous que le chemin d'importation est correct
import { useParams } from 'react-router-dom';
import { getArticlesByAuthorId, addFavorite, removeFavorite } from "../../HTTP/articles";
import { escapeQuotes, formatDateExtended, removeHtmlTags } from "../../Helper/utils";
import Pagination from "../../Helper/Pagination";
import imgArticle from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import { use } from "react";
import { countries } from "../../global/countriesAndSources";

const Friend = () => {
	let { id } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [friend, setFriend] = useState([]);
	const [country, setCountry] = useState('Non défini');
	const user = JSON.parse(localStorage.getItem('user'));

	const url = new URL(window.location.href)
	if (url.searchParams.get('articlepage') === null) {
		url.searchParams.set('articlepage', 1);
		window.history.pushState({}, '', url);
	}
	
	useEffect(() => {
		userApi.getFriend(id).then((f) => {
			setFriend(f);
			if (f.country !== null) {
				const c = countries.find((c) => c.code === f.country);
				console.log(c)
				setCountry(c.name);
			}
	})
	}, [id]);

	const [reviews, setReviews] = useState([]); 
	const [articles, setArticles] = useState({count:0, rows:[]});
	const [currentArticlePage, setCurrentArticlePage] = useState(parseInt(url.searchParams.get('articlepage')));
	const [showArticles, setShowArticles] = useState(false);
	useEffect(() => {
		const fetchReviews = async () => {
		  const reviewsData = await userApi.getUserReviews(id); // Assurez-vous que ceci renvoie un tableau 
		  setReviews(reviewsData.reviews);
		};
	  
		fetchReviews();
	  }, []);
	
	useEffect(() => {
		const fetchArticles = async () => {
			const articlesData = await getArticlesByAuthorId(id, (currentArticlePage - 1) * 3, currentArticlePage * 3, "" , "title", 4);
			console.log(articlesData)
			setArticles(articlesData);
		  }
		  fetchArticles();
	}, [currentArticlePage]);

	console.log(articles)

	const [favorites, setFavorites] = useState(JSON.parse(localStorage.getItem('favorites') || []));

  const handleArticleToFavorite = async (id, title, link, image, country, date, description) => {
    const favorite = {
      title: title,
      link: link,
      image: image || "",
      country: user.country,
      publication_date: date,
      description: description
    };

    try {
      const res = await addFavorite(favorite);
      if (res.ok === true) {
        favorite.id = res.id;
        const updatedFavorites = [...favorites, favorite];
        setFavorites(updatedFavorites);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
        alert('Article added to favorites !');
      }
    } catch (error) {
      console.error('An error has occured when adding the favorite', error);
      alert('An error has occured when adding the favorite');
    }
  };

  const handleRemoveFromFavorite = async (link) => {
    try {
      const res = await removeFavorite({ link });
      if (res) {
        console.log(favorites)
        const updatedFavorites = favorites.filter(fav => fav.link !== link);
        console.log(updatedFavorites)
        setFavorites(updatedFavorites);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
        alert('Article removed from favorites !');
      }
    } catch (error) {
      console.error('An error has occured when deleting the favorite', error);
      alert('An error has occured when deleting the favorite');
    }
  };

  const handleDisplayAddFavoriteButton = (link) => {
    if (JSON.parse(localStorage.getItem("favorites")).find((f) => f.link === link) === undefined) {
      return true;
    }
    return false;
  }

  const ArticleCard = ({ articleId, imageUrl, author, authorProfilePicture, authorId, title, text, date }) => {
	console.log(imageUrl)
    const articleLink = `https://static.zemus.info/article/?id=${articleId}`;
    const isFavorite = favorites.some(fav => fav.link === articleLink);

    const handleFavoriteClick = () => {
      if (isFavorite) {
        handleRemoveFromFavorite(articleLink);
      } else {
        handleArticleToFavorite(
		  articleId,
          title,
          articleLink,
          imageUrl,
          user.country,
          new Date().toISOString().replace("T", " ").slice(0, 19),
          text.length > 150 ? text.slice(0, 50) + "..." : text
        );
      }
    };

    return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl h-full">
      <div className="flex flex-col h-full">
      <a href={`https://static.zemus.info/article/?id=${articleId}`}>
        <img
          className="w-full h-48 object-cover flex-shrink-0"
          src={imageUrl ? imageUrl : imgArticle}
          alt={title}
        />
      </a>
        <div className="p-4 flex-grow flex flex-col">
          <a href={`https://static.zemus.info/article/?id=${articleId}`}>
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            <p className="text-gray-600 flex-grow">
              {text.length > 150 ? text.slice(0, 50) + "..." : text}
            </p>
          </a>
          <div className="flex items-center mb-2">
            <a href={`/friends/${authorId}`} className="flex items-center space-x-2">
              <img
                className="w-8 h-8 rounded-full mr-2"
                src={authorProfilePicture || process.env.PUBLIC_URL+"/profile_default.png"}
                alt="profile_picture_author"
              />
              <p className="text-sm mt-2 notranslate">
                {author}
              </p>
            </a>
            <p className="text-sm mt-2 ml-2 notranslate">
              {formatDateExtended(date)}
            </p>
            <button 
              onClick={handleFavoriteClick}
              className="ml-auto focus:outline-none"
            >
            <img 
              className="w-6 h-6 ml-2" 
              src={process.env.PUBLIC_URL + (isFavorite ? "/bookmark.svg" : "/bookmarklight.svg")} 
              alt="favoris"
            />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

	const articlePagination = (pageNumber) => {
		url.searchParams.set('articlepage', pageNumber);
		window.history.pushState({}, '', url);
		setCurrentArticlePage(pageNumber);
	}

	return (
		<div>
		<div className="profileHeader md:flex md:mt-8">
			<div className="profileHeader-element">  
				<img src={friend.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" } alt="profile" className="profilePicture" />  
			</div>
			<div className="profileHeader-element">  
			<h2 className="notranslate"> 
				{ `${friend?.firstname} ${friend?.lastname}` }
				<img className="verificato" src={verificato} alt="verification" />
			</h2>
				<div className="md:flex">
					{
						friend.phone !== null && friend.phone !== "" && (
							<p className="notranslate"><img src={process.env.PUBLIC_URL+"/sidebar/tel.png"} alt="Phone" className="profilePicto"  />{ `${friend?.phone}` }</p>
						)
					}
					{
						friend.email !== null && friend.email !== "" && (
							<p className="notranslate"><img src={process.env.PUBLIC_URL+"/sidebar/mail_white.png"} alt="email" className="profilePicto" />{ `${friend?.email}` }</p>
						)
					}
				</div>
				<p><img src={process.env.PUBLIC_URL+"/sidebar/terre.png"} alt="Country" className="profilePicto"  />{ `${country}` }</p>
				{
					friend.address !== null && friend.address !== "" && (
						<p><img src={process.env.PUBLIC_URL+"/sidebar/pin.png"} alt="Address" className="profilePicto"  />{ `${friend?.address}` } </p>  
					)
				}  
			</div> 
			{
				friend.bio !== null && friend.bio !== "" && (
					<div className="profileHeader-element">  
						<div className="mt-2 pl-5 pr-5 md:ml-5 border-l-2 border-white profileHeader-bio">
							<h3 className="font-bold mb-2">Biographie</h3>
							<p>{ `${friend?.bio}` } </p>
						</div>
					</div>
				)
			}
		</div>
		<div className="w-full flex justify-center">
			<button
				type="button"
				style={{ backgroundColor: showArticles === true ? '#009aad' : "white", color: showArticles === true ? "white" : "black" }}
				className={`w-full p-2 shadow-sm transition-colors`}
				onClick={() => setShowArticles(false)}
				>
					Revues de presse
			</button>
			<button
				type="button"
				style={{ backgroundColor: showArticles === false ? '#009aad' : "white", color: showArticles === false ? "white" : "black"}}
				className={`w-full p-2 shadow-sm transition-colors`}
				onClick={() => setShowArticles(true)}
				>
					Articles				
			</button>
		</div>
		{
			showArticles === false ? (
				<div className='profileContainer'> 
					<h1 className="ml-4 mt-4 mb-4">Revues de presse</h1> 
					<ReviewBlocks reviews={reviews} edition="false" displayAuthor={false}/>
				</div>
			) : ( <>
		<div className='profileContainer'>
			<h1 className="ml-4 mt-4 mb-4">Articles</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				{articles.rows.map((article, i) => (
					<ArticleCard
						key={i}
						articleId={article.id}
						imageUrl={article.image}
						title={article.title}
						text={removeHtmlTags(article.description)}
						date={article.created_at}
					/>
				))}
			</div>
			</div>
			<Pagination
				itemsPerPage={3}
				totalItems={articles.count}
				paginate={articlePagination}
				currentPage={currentArticlePage}
			/></>)
		}
		</div>
	)
}

export default Friend
