import authHeader from "./auth.header"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

async function getAllFriends(){
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/friends`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function getAllFriendsWithSharedReviews() {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/friends-with-shared-reviews`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

// invitations reçues par le user
async function getFriendRequests(){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/requests`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return []
        }

        return (await response.json()).friend_requests || []
    }
    catch (err) {
        console.log("err at getFriendRequests", err)
        return []
    }
}

// invitations envoyees par le user
async function getInvitations(){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/invitations`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return []
        }
      
        return (await response.json()).friend_requests || []
    }
    catch (err) {
        console.log("err at getInvitations", err)
        return []
    }
}

// Ajouter un ami (invitation)
async function addFriend(friendId){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return []
        }
        else {
            console.log("Ajout ami ok\n. response code, ", response.code)
        }
        return (await response.json()).friend_requests || []
    }
    catch (err) {
        console.log("err at ajout ami", err)
        return []
    }
}
async function searchFriends(s) {
    try { 
        if (!s) return 
        const id = JSON.parse(localStorage.getItem('user')).id
        const response = await fetch(`${HOST_URL}/users/${id}/searchfriends/${s}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
         
        return await response.json()
    }
    catch (err) {
        console.log("err at searchFriend", err)
    }
}
async function searchUsers(s) {
    const id = JSON.parse(localStorage.getItem('user')).id
    if(s === ''){ s = 'all'} //  si vide on veut tout
    try { 
        if (!s) return 
        const response = await fetch(`${HOST_URL}/users/${id}/searchusers/${s}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
         
        return await response.json()
    }
    catch (err) {
        console.log("err at searchUsers", err)
    }
}
async function acceptFriendship(friendId){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
       
        return true
    }
    catch (err) {
        console.log("err at acceptFriendship", err)
    }
}

export async function removeFriend(friendId) {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        console.log(await response.json())
        return true
    }
    catch (err) {
        console.log("err at removeFriend", err)
    }
}

export async function getAllPublicUsers(){
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/public/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

async function getSuggestedFriends(){
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/suggestedfriends`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    } 
    return response.json()
}
async function getFriend(friendId){
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    } 
    return await response.json()
}

async function updatePrivacySetting(id) {
    try { 
        if (!id) return 
        const response = await fetch(`${HOST_URL}/users/${id}/updateprivate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        return true
    }
    catch (err) {
        console.log("err at removeFriend", err)
    }
}

export async function updateUser(id, request) {
    console.log('send requet to API')
    try { 
        if (!id) return 'No user id get lost'
        const response = await fetch(`${HOST_URL}/users/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(request) // Ajout du contenu de 'request' au corps de la requête
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
       const responseData = await response.json()
       console.log(responseData)

       return responseData;
    }
    catch (err) {
        console.log("err at updateUser", err)
    }
}

async function setProfilePic(id, imageFile) { 
    try { 
        if (!id) return 'No user id get lost' 
            const formData = new FormData();
            formData.append('file', imageFile); 
            const response = await fetch(`${HOST_URL}/users/${id}/profile-picture`, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     'Accept': 'multipart/form-data',
                //     ...authHeader()
                // },
                body: formData
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
         
       return response.url;
    }
    catch (err) {
        console.log("err at set Profile pic for user", err)
    }
}

export async function getUserIdFromEmail(email) {
    try {
        const response = await fetch(`${HOST_URL}/users/email/${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })

        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return []
        }

        return await response.json()
    } catch (err) {
        console.log("err at getUserIdFromEmail", err)
    }
}

export async function getUserReviews(userId) {
    if(!localStorage.getItem('user')) return
    if(!userId){
        const userId = JSON.parse(localStorage.getItem('user')).id
    }
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/users/${userId}/reviews`, {
        method: 'GET', 
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        }
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return await response.json()
}
export async function getArticle(articleId) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const response = await fetch(`${HOST_URL}/articles/${articleId}`, {
        method: 'GET', 
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })
    console.log('Fetch article id '+articleId)
    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function getNotifications() {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const response = await fetch(`${HOST_URL}/notifications/${userId}`, {
        method: 'GET', 
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })
    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function updateSeenNotification(notificationId) {
    try {
        const response = await fetch(`${HOST_URL}/notifications/see`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ notificationId })
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message);
        }

        return data;
    } catch (error) {
        console.error('Error marking notification as seen:', error);
        throw error;
    }
}

export async function deleteNotification(notificationId) {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/notifications/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify({ notificationId })
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        console.log(await response.json())
        return true
    }
    catch (err) {
        console.log("err at removeNotification", err)
    }
}

export async function getCountUserNotifications(userId)
 {
    const response = await fetch(`${HOST_URL}/notifications/count/${userId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
 }

 export const isUserSubscribed = async (userId) => {
    try {
        const response = await fetch(HOST_URL + "/subscription/users/" + userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            return Promise.reject(errorData);
        }

        const data = await response.json();
        return Promise.resolve(data);
    } catch (error) {
        console.error(error);
        return Promise.reject({ message: "Une erreur inconnue est survenue." });
    }
}

const user = { 
    getAllFriends,
    getAllFriendsWithSharedReviews,
    getFriendRequests,
    getInvitations,
    acceptFriendship,
    addFriend,
    removeFriend,
    getAllPublicUsers,
    getSuggestedFriends,
    updatePrivacySetting,
    searchFriends,
    searchUsers,
    updateUser,
    getFriend,
    getUserReviews,
    setProfilePic,
    getArticle,
    isUserSubscribed
}
export default user;